export const labelMap = {
  'Attritional risk': 'Attritional',
  'Earthquake risk': 'Earthquake',
  'Flood risk': 'Flood',
  'Missing COPE data': 'COPE',
  Overvalued: 'Overvalued',
  'SCS risk': 'Storm',
  Undervalued: 'Undervalued',
  VALUE_COLLECTION: 'Value Collection',
  'Valuation Outlier': 'Valuation outlier',
  'Wind risk': 'Wind',
};

export const exportReasonLabelMap = {
  'Attritional risk': 'attritional',
  'Earthquake risk': 'earthquake',
  'Flood risk': 'flood',
  'Missing COPE data': 'COPE',
  'SCS risk': 'storm',
  'Wind risk': 'wind',
};
