import { IGraphQLChart } from '@app/queries/explorers/types';
import AalScatter from './Charts/AalScatter';
import AvgYrInPortfolio from './Charts/AvgYrInPortfolio';
import DataQuality from './Charts/DataQuality';
import DataQualityV2 from './Charts/DataQualityV2';
import Inspections from './Charts/Inspections';
import ProvenanceSummary from './Charts/ProvenanceSummary';
import QualityDocuments from './Charts/QualityDocuments';
import RoofYear from './Charts/RoofYear';
import TivBarChart from './Charts/TivBarChart';
import TIVBarsChart from './Charts/TIVBarsChart';
import TivPieChart from './Charts/TivPieChart';
import TivRValue from './Charts/TivRValue';
import TotalLossByCause from './Charts/TotalLossByCause';
import { IExplorersChart } from './types';

type ChartCatalog = { [item: string]: IExplorersChart };
const customCharts: ChartCatalog = {
  aal_summary: {
    Explorer: AalScatter,
    height: 500,
  },
  data_quality: {
    Explorer: DataQuality,
    height: 272.5,
  },
  data_quality_v2: {
    Explorer: DataQualityV2,
    height: 320,
  },
  inspections: {
    Explorer: Inspections,
    height: 194,
  },
  provenance_summary: {
    Explorer: ProvenanceSummary,
    height: 500,
  },
  quality_documents: {
    Explorer: QualityDocuments,
    height: 470,
  },
  seismic_design_r_value_range: {
    Explorer: TivRValue,
    height: 224,
  },

  tiv_by_decade_built: {
    Explorer: TivBarChart,
    height: 224,
  },

  tiv_by_roof_age: {
    Explorer: RoofYear,
    height: 194,
  },

  tiv_by_years_in_portfolio: {
    Explorer: AvgYrInPortfolio,
    height: 299,
  },

  total_loss_by_cause: {
    Explorer: TotalLossByCause,
    height: 320,
  },
};

const defaultcharts: ChartCatalog = {
  TIVChart: {
    Explorer: TivPieChart,
    height: 224,
  },
  TIVCharts: {
    Explorer: TIVBarsChart,
    height: 140,
  },
};
export const getChart = (explorer: IGraphQLChart) => {
  if (!explorer) {
    return null;
  }
  if (customCharts[explorer.name]) {
    return customCharts[explorer.name];
  }
  return defaultcharts[explorer.__typename];
};

export const explorerTitles = {
  data_quality: 'Data quality',
  data_quality_v2: 'Data Quality',
  inspections: 'Inspections',
  quality_construction_type: 'Construction class',
  quality_documents: 'Documentation',
  quality_geo_code_type: 'Geo-code level',
  quality_roof: 'Roof age',
  quality_sprinklers: 'Building sprinklered',
  seismic_design_r_value_range: 'R-Value',
  sprinklers: 'Sprinklers',
  tiv_by_construction_type: 'Construction type',
  tiv_by_construction_type_cluster: 'Construction type',
  tiv_by_country: 'Country',
  tiv_by_decade_built: 'Year built',
  tiv_by_eu_state: 'Country',
  tiv_by_general_occupancy: 'Occupancy type',
  tiv_by_occupancy_type: 'Occupancy type',
  tiv_by_primary_tenant_type: 'Primary tenant',
  tiv_by_roof_age: 'Roof age',
  tiv_by_us_state: 'State',
  tiv_by_years_in_portfolio: 'Years in portfolio',
  tiv_per_built_by: 'Built & Acquired',
  total_loss_by_cause: 'Most severe causes of loss',
};

export const getExplorerTitle = (explorerName: string): string =>
  explorerTitles[explorerName] || explorerName;
