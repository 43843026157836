import { ApolloClient, ApolloError } from '@apollo/client';
import { ExportCode } from '@onarchipelago/cx/Stream/types';
import useStandardQuery from '@app/hooks/useStandardQuery';
import { IGraphQLDocument } from '@app/queries/documents/types';
import { IGraphQLStreamGroup } from '@app/queries/streams/types';
// FIX ME
// @ts-ignore
import GET_DOCUMENTS from './getDocuments.gql';
import { IGroupedDocuments } from './types';

interface IGetDocumentsData {
  streamDocumentsPage?: {
    documents?: Array<IGraphQLDocument>;
  };
}

interface IGetDocumentsVariables {
  slug: string;
  orgName: string;
  cursor: string;
  pageSize: number;
}

interface IInternalGroup<Doc extends IGraphQLDocument> extends IGroupedDocuments<Doc> {
  order: number;
}

interface IInternalGroupObj<Doc extends IGraphQLDocument> {
  [index: string]: IInternalGroup<Doc>;
}

export const DEFAULT_GROUP_NAME = 'Other Docs';

const sortGroupedDocuments = (
  a: IInternalGroup<IGraphQLDocument>,
  b: IInternalGroup<IGraphQLDocument>,
) => {
  if (a.name === DEFAULT_GROUP_NAME) {
    return 1;
  }
  if (b.name === DEFAULT_GROUP_NAME) {
    return -1;
  }

  return a.order - b.order;
};

const DEFAULT_GROUP_NAME_MATCHES = ['none'];

const getGroupName = (streamId: string, streamGroups: IGraphQLStreamGroup[]) => {
  const name = streamGroups.find((item) => item?.stream?.id === streamId)?.group;

  if (!name || DEFAULT_GROUP_NAME_MATCHES.includes(name.toLowerCase())) {
    return DEFAULT_GROUP_NAME;
  }

  return name;
};

export function groupDocuments<Doc extends IGraphQLDocument>(
  streamId: string,
  docs: Array<Doc>,
): Array<IGroupedDocuments<Doc>> {
  return Object.values(
    docs.reduce((obj, document) => {
      const name = getGroupName(streamId, document.streamGroups);

      return {
        ...obj,
        [name]: {
          ...obj[name],
          documents: (obj[name]?.documents || ([] as Array<Doc>)).concat({
            ...document,
            group: name,
          }),
          name,
          order: Object.values(obj).length,
        },
      };
    }, {} as IInternalGroupObj<Doc>),
  )
    .sort(sortGroupedDocuments)
    .map(({ name, documents }) => ({
      documents,
      name,
    }));
}

interface IUseDocumentsResponse {
  loading: boolean;
  error?: ApolloError;
  documents: Array<IGraphQLDocument>;
  client?: ApolloClient<any>;
  refetch: () => void;
}

export const useDocuments = (
  slug: string,
  cursor: string,
  orgName: string,
  pageSize = 500,
): IUseDocumentsResponse => {
  const { data, refetch, ...rest } = useStandardQuery<IGetDocumentsData, IGetDocumentsVariables>(
    GET_DOCUMENTS,
    {
      skip: !orgName,
      variables: {
        cursor,
        orgName,
        pageSize,
        slug,
      },
    },
    {
      errorMessage: 'There was an error loading documents',
      numberOfAllowableErrors: 1,
    },
  );

  return {
    ...rest,
    documents: data?.streamDocumentsPage?.documents || [],
    refetch: () =>
      refetch({
        cursor,
        orgName,
        pageSize,
        slug,
      }),
  };
};

export interface IExportedCoding {
  exportCode: ExportCode;
  extension: string;
  name: string;
  contentType?: string;
}

const CODING_DATA: { [index: string]: IExportedCoding } = {
  [ExportCode.AIR]: {
    contentType: 'text/csv',
    exportCode: ExportCode.AIR,
    extension: 'air',
    name: 'AIR export',
  },
  [ExportCode.RMS]: {
    contentType: 'text/tsv',
    exportCode: ExportCode.RMS,
    extension: 'rms',
    name: 'RMS export',
  },
  [ExportCode.HISTORICAL_CHANGE_VALIDATION]: {
    contentType: 'text/csv',
    exportCode: ExportCode.HISTORICAL_CHANGE_VALIDATION,
    extension: 'csv',
    name: 'Historical Change Validation',
  },
  [ExportCode.ARCHIPELAGO]: {
    contentType: 'application/vnd.ms-excel',
    exportCode: ExportCode.ARCHIPELAGO,
    extension: 'xlsx',
    name: 'XLSX export',
  },
  [ExportCode.RAPID]: {
    contentType: 'application/vnd.ms-excel',
    exportCode: ExportCode.RAPID,
    extension: 'xls',
    name: 'RAPID export',
  },
  [ExportCode.DQD]: {
    contentType: 'application/vnd.ms-excel',
    exportCode: ExportCode.DQD,
    extension: 'xlsx',
    name: 'Data Quality export',
  },
  [ExportCode.CHANGE_ANALYSIS]: {
    contentType: 'application/vnd.ms-excel',
    exportCode: ExportCode.CHANGE_ANALYSIS,
    extension: 'xls',
    name: 'Change Analysis export',
  },
  [ExportCode.ENRICHMENT]: {
    contentType: 'application/vnd.ms-excel',
    exportCode: ExportCode.ENRICHMENT,
    extension: 'xlsx',
    name: 'Enrichment export',
  },
  [ExportCode.DOCUMENTS_ARCHIVE]: {
    contentType: 'application/zip',
    exportCode: ExportCode.DOCUMENTS_ARCHIVE,
    extension: 'zip',
    name: 'Documents export',
  },
  [ExportCode.DQD_INTERNAL]: {
    contentType: 'application/vnd.ms-excel',
    exportCode: ExportCode.DQD_INTERNAL,
    extension: 'xlsx',
    name: 'Detailed Data Quality export',
  },
};

export const getCodingData = (coding: ExportCode): IExportedCoding => {
  if (!CODING_DATA[coding]) {
    throw new Error(`Unsupported coding type: ${coding}`);
  }

  return CODING_DATA[coding];
};
