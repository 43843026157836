import React, { useEffect, useState } from 'react';
import {
  ButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageHeader,
  EuiPanel,
  Spacer,
  Toggle,
} from 'ui';
import { useTracker } from '@app/hooks/useTracker';
import { Helmet } from '../Helmet';
import { OpenTicketButton } from './OpenTicketButton';
import { TicketsTable } from './TicketsTable';

export const SupportPage: React.FC = () => {
  const [includeClosedTickets, setIncludeClosedTickets] = useState<boolean>(false);

  const tracker = useTracker();

  useEffect(() => {
    tracker.track('Support: Page Viewed');
  }, []);

  return (
    <EuiPanel style={{ overflow: 'hidden' }}>
      <EuiFlexGroup direction="column" style={{ height: '100%' }}>
        <EuiFlexItem grow={false}>
          <Helmet title="Support" />
          <EuiPageHeader
            pageTitle="Support"
            rightSideItems={[
              <EuiFlexGroup key="right-side-items-support-page" justifyContent="flexEnd">
                <EuiFlexItem grow={false}>
                  <ButtonEmpty
                    label="Apps documentation"
                    iconName="externalLink"
                    href="https://docs.onarchipelago.com/"
                  />
                </EuiFlexItem>
                <EuiFlexItem data-testid="add-stream-btn" grow={false}>
                  <OpenTicketButton includeClosedTickets={includeClosedTickets} />
                </EuiFlexItem>
              </EuiFlexGroup>,
            ]}
          />
          <Spacer />
          <Toggle
            label="Include closed tickets"
            checked={includeClosedTickets}
            onChange={(e: any) => setIncludeClosedTickets(e.target.checked)}
          />
        </EuiFlexItem>
        <EuiFlexItem style={{ overflowY: 'hidden' }}>
          <TicketsTable includeClosedTickets={includeClosedTickets} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};
