import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import {
  Button,
  ButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFocusTrap,
  EuiTitle,
  useToast,
} from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useTracker } from '@app/hooks/useTracker';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import CondensedSubmissionForm from './CondensedSubmissionForm';
import { SubmissionsDashboardContext } from './Context';
import {
  CREATE_SUBMISSION,
  GET_SUBMISSIONS,
  SUBMISSION_ATTRIBUTE_METADATA,
  SUBMISSION_REGIONS,
  SubmissionAttributeMetadataResp,
  SubmissionAttributeMetadataVariables,
} from './Queries';
import {
  AccountOrgType,
  ESubmissionType,
  FormValues,
  ICreateSubmissionVariables,
  IGetSubmissionsData,
} from './types';
import { toEmailOrBlank } from './utils';
import { getValidatorFromSubmissionData, SubmissionFormMeta, validateAll } from './validator';

interface IProps {
  ctx: SubmissionsDashboardContext;
  refetch?: () => void;
  enterpriseOrg?: string;
}

// FIX ME
// @ts-ignore
const defaultValues: FormValues = {
  accountOrgType: AccountOrgType.Client,
  accountStatus: 'Pending',
  archipelagoTransformations: true,
  assumptions: '',
  broker: '',
  businessType: '',
  comment: '',
  customerStreamSlug: '',
  effectiveDate: moment(),
  expiryDate: moment().add(1, 'year'),
  gusl: 'Ground Up',
  industry: '',
  name: '',
  quoteDueDate: moment(),
  submissionType: ESubmissionType.New,
  underwriter: [],
  underwriterEmail: '',
  underwriterTechnician: [],
  underwriterTechnicianEmail: '',
};

const CreateSubmissionFlyout: React.FC<IProps> = ({ ctx, refetch, enterpriseOrg }) => {
  const { account, refresh } = useAuth();
  const isAdmin = account?.permissions?.admin;
  const { filters, sortBy, pageSize } = ctx;
  const { selectedOrganization } = useUserSession();
  const carrierOrgName =
    enterpriseOrg || selectedOrganization?.name || account?.submissionCreateOrgs?.[0]?.name;
  const orgName = isAdmin ? carrierOrgName : null;
  const toast = useToast();
  const { closeFlyout } = useContext(FlyoutContext);
  const [values, setValues] = useState<FormValues>(defaultValues);
  const [meta, setMeta] = useState<SubmissionFormMeta>({});
  const tracker = useTracker();

  if (!account || !carrierOrgName) {
    return null;
  }

  const { data } = useQuery<SubmissionAttributeMetadataResp, SubmissionAttributeMetadataVariables>(
    SUBMISSION_ATTRIBUTE_METADATA,
    {
      variables: { input: { orgName: carrierOrgName } },
    },
  );

  const { data: regionData } = useQuery(SUBMISSION_REGIONS, {
    variables: { input: { orgName: carrierOrgName } },
  });

  const validator = getValidatorFromSubmissionData(data, true);

  const [createSubmission, { loading: saving }] = useMutation<any, ICreateSubmissionVariables>(
    CREATE_SUBMISSION,
    {
      onCompleted: () => {
        toast({
          label: `Your SOV has been received. You'll be notified when your SOV is available on Archipelago.`,
          title: 'Success',
          type: 'success',
        });
        tracker.track('Create Account: Success');
        if (!isAdmin) {
          refresh();
        }
        closeFlyout();
      },
      onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
      update(cache, { data: { createSubmission: newItem } }) {
        const query = {
          query: GET_SUBMISSIONS,
          variables: { input: { filters, orgName, pageSize, sortBy } },
        };
        const cached: IGetSubmissionsData | null = cache.readQuery(query);
        if (cached?.submissionsPageV2?.submissions) {
          const data = {
            ...cached,
            submissionsPageV2: {
              ...cached.submissionsPageV2,
              submissions: [...cached.submissionsPageV2.submissions, newItem],
            },
          };
          cache.writeQuery({ ...query, data });
        }
      },
    },
  );

  const closeAndRecord = () => {
    tracker.track('Create Account: Closed');
    closeFlyout();
  };

  const onSave = () => {
    const submission = {
      ...values,
      effectiveDate: values.effectiveDate.format('YYYY-MM-DD'),
      expiryDate: values.expiryDate.format('YYYY-MM-DD'),
      otherDocs: undefined,
      quoteDueDate: values.quoteDueDate.format('YYYY-MM-DD'),
      teamMemberEmails: values?.teamMemberEmails || [],
      underwriter: values.underwriter[0]?.label,
      underwriterEmail: toEmailOrBlank(values?.underwriter[0]?.value),
      underwriterTechnician: values.underwriterTechnician?.[0]?.label || '',
      underwriterTechnicianEmail: toEmailOrBlank(values.underwriterTechnician?.[0]?.value),
    };

    delete submission.documents;
    delete submission.customerStreamSlug;
    createSubmission({
      variables: {
        input: {
          carrierOrgName,
          customerStreamSlug: values.customerStreamSlug,
          documents: values.documents as Array<File>,
          submission,
        },
      },
    }).then(() => refetch());
  };

  return (
    <EuiFocusTrap>
      <EuiFlyout ownFocus onClose={closeAndRecord} aria-labelledby="flyoutTitle" size="s">
        <EuiFlyoutHeader>
          <EuiTitle size="m">
            <h2>Create Client Account</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <CondensedSubmissionForm
            formData={data?.submissionAttributeMetadata}
            values={values}
            setValues={setValues}
            meta={meta}
            setMeta={setMeta}
            saving={saving}
            validator={validator}
            regionTeamsData={regionData?.submissionRegions?.regions}
          />
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiFlexItem grow={false}>
              <ButtonEmpty onClick={closeAndRecord} label="Cancel" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Button
                data-testid="submission-submit"
                onClick={onSave}
                fill
                loading={saving}
                disabled={!validateAll(validator, values) || saving}
                label={saving ? 'Saving' : 'Save'}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiFocusTrap>
  );
};

export default CreateSubmissionFlyout;
