import React, { useState } from 'react';
import { getShortDate } from '@onarchipelago/cx/Stream/utils';
import { ButtonIcon, EuiBasicTable, Spacer } from 'ui';
import { ISnapshot, IStream } from '@app/cx/Stream/types';
import { SnapshotInfo } from '@app/queries/streams/snapshots/types';
import { formatBoolean, formatDefault } from '@app/utils/format';
import ExposureIndexButton from './ExposureIndexButton';
import SnapshotEditModal from './SnapshotEditModal';

interface Props {
  stream: IStream;
  snapshots: Array<ISnapshot>;
  snapshotInfo: Array<SnapshotInfo>;
  publishedSnapshotId: string | null;
  simplifiedStreamSetting: boolean;
}

const SnapshotTable: React.FC<Props> = ({
  stream,
  snapshotInfo = [],
  snapshots,
  publishedSnapshotId,
  simplifiedStreamSetting,
}) => {
  const [selectedSnapshot, setSelectedSnapshot] = useState<ISnapshot>();

  const columns = [
    {
      field: 'edit',
      name: '',
      render: (snapshot: ISnapshot) => (
        <ButtonIcon iconName="edit" onClick={() => setSelectedSnapshot(snapshot)} />
      ),
      width: '5%',
    },
    { field: 'name', name: 'Name', width: '10%' },
    { field: 'date', name: 'Date', width: '10%' },
    { field: 'displayDate', name: 'Display Date', width: '10%' },
    { field: 'description', name: 'Description', width: '10%' },
    { field: 'bound', name: 'Bound', width: '5%' },
    {
      field: 'marketVisible',
      name: 'Market Visible',
      width: '10%',
    },
  ];

  if (!simplifiedStreamSetting) {
    columns.push(
      // FIX ME
      // @ts-ignore
      ...[
        { field: 'visible', name: 'Visible', width: '5%' },
        { field: 'type', name: 'Type', width: '10%' },
        {
          field: 'exposureIndex',
          name: 'Exposure Index',
          render: (snapshotInfo: SnapshotInfo) => (
            <ExposureIndexButton streamSlug={stream.slug} snapshotInfo={snapshotInfo} />
          ),
          width: '15%',
        },
      ],
    );
  }

  const infoMap = {};
  snapshotInfo.forEach((i) => (infoMap[i.snapshot.id] = i));

  const items = snapshots
    .filter((snapshot) =>
      simplifiedStreamSetting ? snapshot.type === 'STANDARD' && snapshot.visible : true,
    )
    .map((snapshot) => ({
      bound: formatBoolean(snapshot.bound, 'No'),
      date: snapshot.shortDateLabel,
      description: formatDefault(snapshot.description, '-'),
      displayDate: getShortDate(snapshot.displayDate),
      edit: snapshot,
      exposureIndex: infoMap[snapshot.id],
      // FIX ME
      // @ts-ignore
      marketVisible: formatBoolean(snapshot.marketVisible, 'No'),

      name: snapshot.label,

      qualityScore: snapshot,

      type: snapshot.type,

      visible: formatBoolean(snapshot.visible, 'No'),
    }));

  const getRowProps = (snapshot) => {
    if (snapshot.marketVisible === 'Yes') {
      return {
        style: { backgroundColor: 'rgb(245, 250, 253)' },
      };
    }
  };

  return (
    <>
      <Spacer />
      <EuiBasicTable
        rowProps={getRowProps}
        data-testid="stream-edit-modal-snapshot-table"
        columns={columns}
        items={items}
      />
      {selectedSnapshot && (
        <SnapshotEditModal
          onClose={() => setSelectedSnapshot(undefined)}
          publishedSnapshotId={publishedSnapshotId}
          snapshot={selectedSnapshot}
          stream={stream}
        />
      )}
    </>
  );
};

export default SnapshotTable;
