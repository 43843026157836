import { ReactElement } from 'react';
import { FilterQuery } from '@app/components/Filter/types';
import { IMapPosition } from '@app/components/GoogleMap/types';
import { IGraphQLSort } from '@app/cx/Stream/Reef/PropertiesPageQuery/types';
import { IGraphQLSnapshot, OrgInfo } from '@app/queries/streams/types';
import { Section } from '../PropertyPage/types';
import { MODE } from './Distillers/types';

export enum ExportCode {
  AIR = 'AIR',
  ARCHIPELAGO = 'ARCHIPELAGO',
  HISTORICAL_CHANGE_VALIDATION = 'HISTORICAL_CHANGE_VALIDATION',
  CHANGE_ANALYSIS = 'CHANGE_ANALYSIS',
  DIAGNOSTIC = 'DIAGNOSTIC',
  DOCUMENTS_ARCHIVE = 'DOCUMENTS_ARCHIVE',
  DQD = 'DQD',
  ENRICHMENT = 'ENRICHMENT',
  RAPID = 'RAPID',
  RMS = 'RMS',
  LOSSES = 'LOSSES',
  DQD_INTERNAL = 'DQD_INTERNAL',
}

export const reportCodes = [
  'ZurichUwAR',
  'DQD',
  'EnrichmentReport',
  'EngagementReport',
  'RMAssessment',
  'ExposureSummary',
  'ValuationSummary',
] as const;
export type ReportCode = (typeof reportCodes)[number];

export interface IDistillerOption {
  value: string | number;
  label: string | ReactElement;
}

export interface IDistillerInput {
  label: string;
  name: string;
  value?: string | number;
  options?: Array<{
    value: string;
    label: string | ReactElement;
  }>;
}

export interface IDistiller {
  label: string | JSX.Element;
  name?: string;
  options?: Array<IDistillerOption>;
  inputs?: Array<IDistillerInput>;
  enableNone?: boolean;
  value?: string[];
  order: number;
  visible?: (mode: MODE) => boolean;
}

export interface IDistillers {
  [index: string]: IDistiller;
}

export interface IStreamConfiguration {
  explorersConfig: string;
  notificationEmails: string[];
  lastUpdateNote: string;
  membershipExpression: string;
  exportsEnabled: boolean;
  allowedExports: ExportCode[];
  reportsEnabled: boolean;
  allowedReports: ReportCode[];
  allowableReports: ReportCode[];
  defaultStreamView: string;
  shareLossData: boolean;
  submissionDashboardOrgs: OrgInfo[];
  submissionDashboardOrgNames: string[];
}

export interface IStream {
  broker: string;
  documentsCount: number;
  effectiveDate: Date | undefined;
  expiryDate: Date | undefined;
  id: string;
  isPublic: boolean;
  name: string;
  numberOfUpdates: number;
  organizationId: undefined | string;
  orgName: string;
  propertiesCount: number;
  reit: string;
  slug: string;
  status: string;
  totalInsuredValue: number;
  updateStatus: number;
  highlights: string;
  // TODO: Remove these
  createdAt: Date;
  // numberOfDocuments: number;
  properties: Array<any>;
  allowedExports: ExportCode[];
  allowedReports: ReportCode[];
  propertyStatusLabel: string;
  defaultSnapshot?: string;
  configuration: IStreamConfiguration;
  isMyProperties: boolean;
  stockThroughputEnabled: boolean;
  updateMembership?: boolean;
  industry: any;
  businessType: any;
}

export interface GroupByV2 {
  attributeName: string;
  value: string;
  activeGroupIndex?: number;
}

export interface IPageState {
  mode: MODE;
  explorerTab: null | string;
  explorerPosition: null | string;
  currentSnapshot: null | string;
  changesSince: null | string;
  sizeBy: null | string;
  filters: null | FilterQuery;
  userCode: null | string;
  sort: IGraphQLSort;
  gridPosition: null | string;
  expandedColumns: null | string;
  highlights: null | string;
  mapPosition: null | IMapPosition;
  comments: null | string;
  commentsAttribute: null | string;
  groupByV2: null | GroupByV2[];
  propertyPageTab: null | Section;
}

export interface ISnapshot extends IGraphQLSnapshot {
  label: string;
  shortDateLabel: string;
  longDateLabel: string;
}

export interface StreamPageOptions {
  enableDocumentsBtn?: boolean;
  enableStreamInviteBtn?: boolean;
  enableStreamEditBtn?: boolean;
  enabledRunIntegrationsBtn?: boolean;
}

export enum XlsxExportModes {
  ALL = 'all',
  FILTERED = 'filtered',
}
