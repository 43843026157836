import * as Types from '../../../precheck.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DataCompletenessByOrgQueryVariables = Types.Exact<{
  input: Types.DataCompletenessByOrgInput;
}>;


export type DataCompletenessByOrgQuery = { __typename?: 'Query', dataCompletenessByOrg: { __typename?: 'DataCompletenessByOrgResponse', taskStats?: Array<{ __typename?: 'TaskStat', status: Types.TaskStatus, totalCount: number }> | null } };


export const DataCompletenessByOrgDocument = gql`
    query DataCompletenessByOrg($input: DataCompletenessByOrgInput!) {
  dataCompletenessByOrg(input: $input) {
    taskStats {
      status
      totalCount
    }
  }
}
    `;

/**
 * __useDataCompletenessByOrgQuery__
 *
 * To run a query within a React component, call `useDataCompletenessByOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataCompletenessByOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataCompletenessByOrgQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDataCompletenessByOrgQuery(baseOptions: Apollo.QueryHookOptions<DataCompletenessByOrgQuery, DataCompletenessByOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataCompletenessByOrgQuery, DataCompletenessByOrgQueryVariables>(DataCompletenessByOrgDocument, options);
      }
export function useDataCompletenessByOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataCompletenessByOrgQuery, DataCompletenessByOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataCompletenessByOrgQuery, DataCompletenessByOrgQueryVariables>(DataCompletenessByOrgDocument, options);
        }
export type DataCompletenessByOrgQueryHookResult = ReturnType<typeof useDataCompletenessByOrgQuery>;
export type DataCompletenessByOrgLazyQueryHookResult = ReturnType<typeof useDataCompletenessByOrgLazyQuery>;
export type DataCompletenessByOrgQueryResult = Apollo.QueryResult<DataCompletenessByOrgQuery, DataCompletenessByOrgQueryVariables>;