import React, { useEffect, useState } from 'react';
import { EuiSpacer, Tabs } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useEnterpriseDashboardMetadataQuery } from '@app/graphql/queries/enterpriseDashboardMetadata/metadata/__generated__/getEnterpriseDashboardMetadata.generated';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { useTracker } from '@app/hooks/useTracker';
import { GoodDataIntegration } from '../GoodDataIntegration/GoodDataIntegration';

export const Charts = () => {
  const apiTracker = useTracker();
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;

  const [selectedDashboard, setSelectedDashboard] = useState(null);

  const isAdminAndHubAnalyticsEnabled = isAdmin;

  const { data: orgInfoData } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });
  const { data } = useEnterpriseDashboardMetadataQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const dashboardID = data?.enterpriseDashboardMetadata?.dashboards?.[0]?.id;
  const workspaceID = data?.enterpriseDashboardMetadata?.workspaceID;
  const apiToken = data?.enterpriseDashboardMetadata?.signedJWT;

  useEffect(() => {
    if (dashboardID) {
      setSelectedDashboard(dashboardID);
    }
  }, [data]);

  useEffect(() => {
    apiTracker.track('View Enterprise Reporting', {
      event_surface: 'PreCheck',
      organization_id: selectedOrganization?.id,
    });
  }, []);

  const orgInfoPermissions = orgInfoData?.orgInfo?.permissions.viewEnterpriseReports;

  if ((!isAdminAndHubAnalyticsEnabled && !orgInfoPermissions) || !data) {
    return null;
  }

  return (
    <>
      <Tabs
        selectedTab={selectedDashboard}
        tabs={data?.enterpriseDashboardMetadata.dashboards?.map((dash) => ({
          id: dash.id,
          label: dash.title,
          onClick: () => {
            setSelectedDashboard(dash.id);
          },
        }))}
      />
      <EuiSpacer size="m" />
      <GoodDataIntegration
        setSelectedDashboard={setSelectedDashboard}
        apiToken={apiToken}
        workspaceID={workspaceID}
        dashboardID={selectedDashboard || dashboardID}
      />
    </>
  );
};
