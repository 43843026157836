import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  ButtonEmpty,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiTextArea,
  TextField,
} from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useOpenSupportTicketMutation } from '@app/graphql/mutations/support/__generated__/openSupportTicket.generated';
import { GET_SUPPORT_TICKETS_PAGE } from '@app/graphql/queries/support/getSupportTicketsPage';
import { BinaryOperator, SortOrder, TicketStatus } from '@app/graphql/types';
import { useTracker } from '@app/hooks/useTracker';
import { UserSelect } from './UserSelect';

const OpenTicketFlyout = ({ includeClosedTickets }) => {
  const { closeFlyout } = useFlyout();
  const {
    account: { permissions, email },
  } = useAuth();

  const { selectedOrganization } = useUserSession();
  const history = useHistory();
  const tracker = useTracker();
  const [openSupportTicket, { loading }] = useOpenSupportTicketMutation({
    onCompleted: (ticket) => {
      tracker.track('Support: Ticket Created');
      history.push(`/support?ticketId=${ticket?.openSupportTicket?.ticket?.chatRoomID || ''}`);
      closeFlyout();
    },
    refetchQueries: [
      {
        query: GET_SUPPORT_TICKETS_PAGE,
        variables: {
          input: {
            cursor: '',
            filter: [
              {
                name: 'customFields.status',
                operator: includeClosedTickets ? BinaryOperator.In : BinaryOperator.NotIn,
                values: [TicketStatus.Closed, TicketStatus.Resolved],
              },
            ],
            includeClosedTickets: null,
            sort: [{ attributeName: 'ts', order: SortOrder.Descending }],
          },
        },
      },
    ],
  });

  const [title, setTitle] = useState<string>();
  const [assigneeEmails, setAssigneeEmails] = useState<string[]>([]);
  const [followerEmails, setFollowerEmails] = useState<string[]>([]);

  const [description, setDescription] = useState<string>();

  const submit = () => {
    openSupportTicket({
      variables: {
        input: {
          assigneeEmails,
          authorEmail: email,
          description,
          followerEmails,
          orgName: selectedOrganization?.name,
          title,
        },
      },
    });
  };

  return (
    <EuiFlyout onClose={closeFlyout} size="s">
      <EuiFlyoutHeader>New ticket</EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiForm onSubmit={submit}>
          <EuiFormRow label="Title">
            <TextField value={title} onChange={(e) => setTitle(e.target.value)} />
          </EuiFormRow>
          <EuiFormRow label="Description">
            <EuiTextArea
              placeholder="Describe your problem here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </EuiFormRow>
          {!!permissions?.admin && (
            <>
              <EuiFormRow label="Assign to">
                <UserSelect
                  onChange={(emails) => setAssigneeEmails(emails)}
                  initialSelected={assigneeEmails}
                  placeholder="Search internal archipelago users by email"
                  internalUsersOnly
                />
              </EuiFormRow>
              <EuiFormRow label="Followers">
                <UserSelect
                  onChange={(emails) => setFollowerEmails(emails)}
                  initialSelected={followerEmails}
                  placeholder="Search users by email"
                  allowUnknowns
                />
              </EuiFormRow>
            </>
          )}
          <Button label="Submit" onClick={submit} loading={loading} />
        </EuiForm>
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};

export const OpenTicketButton = ({ includeClosedTickets }) => {
  const { showFlyout } = useFlyout();
  const tracker = useTracker();
  return (
    <ButtonEmpty
      label="Open ticket"
      onClick={() => {
        tracker.track('Support: Ticket Flyout Opened');
        showFlyout(<OpenTicketFlyout includeClosedTickets={includeClosedTickets} />);
      }}
    />
  );
};
