import { TaskStatus, TaskType, ValuationOutlierReason } from '@app/graphql/precheck/precheck.types';
import { catReasons, outlierReasons } from '../TaskContext';
import {
  AttritionalReason,
  CATReasons,
  COPEReason,
  IFormattedtask,
  ValuationReason,
} from '../types';

const filterReasonsTasks = (
  filters,
  taskTotals,
  taskData: IFormattedtask[],
  availableReasons: string[],
) => {
  let data = [...taskData];
  const availableCatReasons = catReasons.filter((reason) => availableReasons.includes(reason));
  const availableOutlierReasons = outlierReasons.filter((reason) =>
    availableReasons.includes(reason),
  );

  if (filters.reasons.includes(CATReasons.anyCat)) {
    data = data.filter((task) => catReasons.some((reason) => task.reasons.includes(reason)));
  }

  if (!filters.reasons.includes(CATReasons.anyCat) && availableCatReasons.length > 0) {
    data = data.filter((task) =>
      availableCatReasons.every((reason) => task.reasons.includes(reason)),
    );
  }

  if (filters.reasons.includes(ValuationReason.any)) {
    data = data.filter((task) =>
      outlierReasons.some(
        (reason) => reason !== ValuationReason.any && task.reasons.includes(reason),
      ),
    );
  }

  if (!filters.reasons.includes(ValuationReason.any) && availableOutlierReasons.length > 0) {
    data = data.filter((task) =>
      availableOutlierReasons.every((reason) => task.reasons.includes(reason)),
    );
  }

  if (availableReasons.includes(COPEReason.cope)) {
    data = data.filter((task) => task.reasons.includes(COPEReason.cope));
  }

  data.forEach((task) => {
    taskTotals.filteredTiv += task.rawTiv;
  });

  return data;
};

export const filterAndCalculateTotals = (filters, tasks, reasonOptions, taskType) => {
  const taskTotals = {
    attritional: {
      properties: 0,
      tiv: 0,
    },
    cat: {
      properties: 0,
      tiv: 0,
    },
    cope: {
      properties: 0,
      tiv: 0,
    },
    filteredTiv: 0,
    readyTasksCount: 0,
    totalSelectedCount: 0,
    valuation: {
      properties: 0,
      tiv: 0,
    },
  };

  // value collection tasks are filtered in api, do not apply filters
  // in memory
  if (taskType === TaskType.ValueCollection) {
    taskTotals.readyTasksCount = tasks.length;
    return { filteredTasks: tasks, taskTotals };
  }

  const filteredTasks = tasks.filter((task) => {
    if (task.status === TaskStatus.Ready) {
      taskTotals.readyTasksCount += 1;

      const hasCat = Object.values(CATReasons).some((reason) => task.reasons.includes(reason));
      if (hasCat) {
        taskTotals.cat.properties += 1;
        taskTotals.cat.tiv += task.rawTiv;
      }

      task.reasons.forEach((reason) => {
        if (reason === AttritionalReason.attritional) {
          taskTotals.attritional.properties += 1;
          taskTotals.attritional.tiv += task.rawTiv;
        }

        if (reason === COPEReason.cope) {
          taskTotals.cope.properties += 1;
          taskTotals.cope.tiv += task.rawTiv;
        }

        if (
          reason === ValuationOutlierReason.Overvalued ||
          reason === ValuationOutlierReason.Undervalued
        ) {
          taskTotals.valuation.properties += 1;
          taskTotals.valuation.tiv += task.rawTiv;
        }
      });
    }

    const nameMatch =
      filters.name?.length > 0
        ? task.propertyName.toLocaleLowerCase().includes(filters.name.toLocaleLowerCase()) ||
          task.propertyCityAndState.toLocaleLowerCase().includes(filters.name.toLocaleLowerCase())
        : true;

    const assigneeMatch =
      filters.users.length === 0
        ? true
        : filters.users.some((user) => task.assignees.some((assignee) => assignee.email === user));

    const dismissedMatch = filters.dismissed
      ? task.status === TaskStatus.Dismissed
      : task.status === TaskStatus.Ready;

    if (nameMatch && assigneeMatch && dismissedMatch) {
      return true;
    } else {
      return false;
    }
  });

  const finalFilteredTasks = filterReasonsTasks(
    filters,
    taskTotals,
    filteredTasks as IFormattedtask[],
    [...(filters.reasons.filter((item) => reasonOptions[item]) || [])],
  ) as Array<IFormattedtask>;

  return { filteredTasks: finalFilteredTasks, taskTotals };
};
