import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Button,
  EuiBasicTable,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiText,
  HorizontalAlignment,
} from 'ui';
import { ModalWithButtons } from '@app/components/ModalWithButtons/ModalWithButtons';
import { useUploads } from '@app/contexts/UploadsContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { AttributeRow, AttributeRowTypeEnum } from '@app/cx/Stream/AddPropertyFlyout/AttributeRow';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { RecommendationType } from '@app/graphql/precheck/precheck.types';
import DocumentUpload from '@app/platform/Library/DocumentUpload/DocumentUpload';
import { SectionLabel, TooltipContainer, TooltipIcon } from './ValuationOutlier.emotion';

// editable fields
export const subValues = [
  'buildingValue',
  'improvementsValue',
  'miscBuildingReplacementCostValue',
  'buildingReplacementCost',
];

const inputFields = [...subValues, 'floorArea'];

// ordering of line items
export const lineItemNameIds = [...subValues, 'floorArea', 'replacementCostPerSquareFootage'];
const boldedLineItems = ['replacementCostPerSquareFootage'];

export const getCurrencyValue = ({ currency, value }) =>
  new Intl.NumberFormat('en-US', {
    currency,
    minimumFractionDigits: 0,
    style: 'currency',
  }).format(value || 0);

export const ValuationOutlier = ({ task, defaultValues, formValues, setValue, refetch }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [showAppraisalWarning, setShowAppraisalWarning] = useState(false);
  const [uploadingDocument, setUploadingDocument] = useState('');
  const { selectedOrganization } = useUserSession();
  const { stream: streamContext } = useStreamContext();
  const { uploads } = useUploads();

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 4000);
  }, [Object.values(uploads).length]);

  const isUploading = Object.values(uploads).some((upload) => upload.progress === 0);

  const isDocumentUploaded =
    uploadingDocument !== '' &&
    !task.propertyDocuments
      .map((document) => document.filename)
      .some((item) => uploadingDocument.includes(item));

  useEffect(() => {
    if (isUploading) {
      setShowAppraisalWarning(true);
      setUploadingDocument(
        Object.values(uploads).find((upload) => upload.progress === 0)?.filename,
      );
    }
  }, [isUploading]);

  const { watch } = useFormContext();

  // recalculate fields
  watch(inputFields);

  const totalBuildingReplacementCost = subValues.reduce(
    (acc, lineItemId) => acc + (defaultValues[lineItemId] || 0),
    0,
  );

  const buildingReplacementCost = task.property?.buildingReplacementCost;

  const isFormModified = useMemo(
    () => Object.keys(formValues).some((key) => formValues[key] !== defaultValues[key]),
    [formValues, defaultValues],
  );

  const newTotalBuildingReplacementCost = buildingReplacementCost
    ? formValues['buildingReplacementCost']
    : subValues.reduce((acc, lineItemId) => acc + (formValues[lineItemId] || 0), 0);

  const newReplacementCostPerFloorAreaUnit = newTotalBuildingReplacementCost / formValues.floorArea;

  const buildingReplacementCostExists = task.attributes
    .filter((attribute) => attribute.recommendationType === RecommendationType.ValuationOutlier)
    .find((attribute) => attribute.name === 'buildingReplacementCost');

  const columns = [
    {
      field: 'attribute',
      name: (
        <EuiText>
          <h5>Attribute</h5>
        </EuiText>
      ),
      render: (name, data) => {
        if (boldedLineItems.includes(data.name) || data.name === 'buildingReplacementCost') {
          return <b>{name}</b>;
        }
        return name;
      },
    },
    {
      align: 'right' as HorizontalAlignment,
      field: 'value',
      name: (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems="center" gutterSize="s" direction="row">
            <EuiText>
              <h5>Current Value</h5>
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
      ),
      render: (value, data) => {
        if (boldedLineItems.includes(data.name) || data.name === 'buildingReplacementCost') {
          return <b>{value}</b>;
        }
        return value;
      },
    },
    {
      align: 'right' as HorizontalAlignment,
      field: 'newValue',
      name: (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems="center" gutterSize="s" direction="row">
            <EuiText>
              <h5>New Value</h5>
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
      ),
      render: (_value, data) => {
        if (
          boldedLineItems.includes(data.name) ||
          (data.name === 'buildingReplacementCost' && !buildingReplacementCostExists)
        ) {
          return <b>{data.newValue}</b>;
        }

        return (
          <AttributeRow
            id={data.name}
            inputProps={{
              append: data.name === 'floorArea' ? null : <b>{streamContext?.displayCurrency}</b>,
              disabled: false,
              intlConfig:
                data.name === 'floorArea'
                  ? null
                  : { currency: streamContext?.displayCurrency, locale: 'en-US' },
              onChange: (value) => {
                setValue(data.name, value);
              },
            }}
            type={AttributeRowTypeEnum.formattedValue}
          />
        );
      },
    },
  ];

  return (
    <EuiFlexGroup direction="column">
      <EuiText size="m">
        <h4>This property has been flagged as potentially overvalued.</h4>
      </EuiText>
      <EuiFlexGroup direction="row">
        <DocumentUpload organizationId={selectedOrganization.id} propertyID={task.propertyId}>
          <Button label="Upload Appraisal" loading={isDocumentUploaded || isUploading} />
        </DocumentUpload>
      </EuiFlexGroup>
      <EuiForm>
        <EuiBasicTable
          columns={columns}
          items={lineItemNameIds
            .reduce((acc, lineItemId) => {
              const valuationOutlier = task.attributes
                .filter(
                  (attribute) =>
                    attribute.recommendationType === RecommendationType.ValuationOutlier,
                )
                .find((attribute) => attribute.name === lineItemId);

              if (valuationOutlier) {
                return [...acc, valuationOutlier];
              }

              // if it's not returned from the server, we still need to show it
              if (lineItemId === 'buildingReplacementCost') {
                return [
                  ...acc,
                  {
                    attributeMetadata: {
                      displayName: 'Replacement Cost Value',
                      name: 'buildingReplacementCost',
                    },
                    name: lineItemId,
                  },
                ];
              }

              return acc;
            }, [])
            .map((attribute) => {
              if (attribute.name === 'buildingReplacementCost') {
                return {
                  attribute: (
                    <SectionLabel gutterSize="xs">
                      {attribute.attributeMetadata.displayName}
                      <TooltipContainer
                        content={
                          <EuiText>
                            Total building replacement cost = building value + improvements /
                            betterments + misc. building replacement cost
                          </EuiText>
                        }
                      >
                        <TooltipIcon name="help"></TooltipIcon>
                      </TooltipContainer>
                    </SectionLabel>
                  ),
                  name: attribute.attributeMetadata.name,
                  newValue:
                    streamContext?.displayCurrency &&
                    getCurrencyValue({
                      currency: streamContext?.displayCurrency,
                      value: newTotalBuildingReplacementCost || 0,
                    }),
                  value:
                    streamContext?.displayCurrency &&
                    getCurrencyValue({
                      currency: streamContext?.displayCurrency,
                      value: buildingReplacementCost || totalBuildingReplacementCost || 0,
                    }),
                };
              }
              const value = task.property?.[attribute.name];
              return {
                attribute:
                  attribute.attributeMetadata.name === 'replacementCostPerSquareFootage' ? (
                    <SectionLabel gutterSize="xs">
                      {attribute.attributeMetadata.displayName}
                      <TooltipContainer
                        content={
                          <EuiText>
                            Building replacement cost per floor area = Total building replacement
                            cost ÷ floor area
                          </EuiText>
                        }
                      >
                        <TooltipIcon name="help"></TooltipIcon>
                      </TooltipContainer>
                    </SectionLabel>
                  ) : (
                    attribute.attributeMetadata.displayName
                  ),
                attributeMetadata: attribute.attributeMetadata,
                name: attribute.attributeMetadata.name,

                newValue:
                  attribute.attributeMetadata.name === 'floorArea'
                    ? new Intl.NumberFormat('en-US').format(value || 0)
                    : streamContext?.displayCurrency &&
                      getCurrencyValue({
                        currency: streamContext?.displayCurrency,
                        value:
                          attribute.attributeMetadata.name === 'replacementCostPerSquareFootage'
                            ? newReplacementCostPerFloorAreaUnit
                            : value || 0,
                      }),
                value:
                  attribute.attributeMetadata.name === 'floorArea'
                    ? new Intl.NumberFormat('en-US').format(value || 0)
                    : streamContext?.displayCurrency &&
                      getCurrencyValue({
                        currency: streamContext?.displayCurrency,
                        value: value || 0,
                      }),
              };
            })}
        ></EuiBasicTable>
      </EuiForm>
      {isFormModified && (
        <EuiCallOut
          title="Please confirm all valuation changes are accurate before proceeding"
          color="warning"
          iconType="warning"
        />
      )}
      {showAppraisalWarning && !isUploading && !isDocumentUploaded && (
        <EuiCallOut
          title="Property replacement cost values are not extracted from appraisal documents"
          color="warning"
          iconType="warning"
        />
      )}
      {isModalShown && (
        <ModalWithButtons
          buttonCloseLabel="Close"
          onClose={() => {
            setIsModalShown(false);
          }}
          header="Valuation Requested"
          subtext="Your request has been received. A support ticket has been created and you can view the status on the support tab."
        />
      )}
    </EuiFlexGroup>
  );
};
