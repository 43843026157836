import React from 'react';
import { useHistory } from 'react-router';
import { Button, EuiBasicTable, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiTitle } from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { colorMap } from '@app/precheck/Components/V2/constants/colorMap';
import { labelMap } from '@app/precheck/Components/V2/constants/labelMap';
import { useTaskContext } from '@app/precheck/Components/V2/context/TaskContext';
import { StyledBadge } from '@app/precheck/Components/V2/TaskTable.emotion';
import EmptyMessage from '../EmptyMessage/EmptyMessage';

export const RecommendationSummary = () => {
  const { tasks, loading } = useTaskContext();
  const history = useHistory();

  const { selectedOrganization } = useUserSession();

  const { data: orgInfoData } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const isEnterpriseAccount = orgInfoData?.orgInfo?.orgInfo?.orgLevel === 'Enterprise';

  if (tasks?.length === 0 && !loading) {
    return (
      <EuiPanel paddingSize="l">
        <EuiTitle size="xs">
          <p>Recommendations</p>
        </EuiTitle>
        <EmptyMessage subText="No action items assigned to you" />
      </EuiPanel>
    );
  }

  const enterpriseColumns = [
    {
      name: 'Account Name',
      render: (task) => (
        <EuiFlexGroup wrap gutterSize="xs">
          <EuiFlexItem grow={false}>{task?.property?.orgName}</EuiFlexItem>
        </EuiFlexGroup>
      ),
      width: '20%',
    },
  ];

  const columns = [
    {
      name: 'Property',
      render: (task) => (
        <EuiFlexGroup gutterSize="none" direction="column">
          <EuiFlexItem style={{ fontWeight: 600 }} grow={false}>
            {task.propertyName}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{task.propertyCityAndState}</EuiFlexItem>
        </EuiFlexGroup>
      ),
      width: '25%',
    },
    {
      field: 'tiv',
      name: 'TIV',
      sortable: true,
      width: '7%',
    },
    {
      field: 'reasons',
      name: 'Reason for recommendation',
      render: (reasons) => (
        <EuiFlexGroup wrap gutterSize="xs">
          {reasons.map((reason, index) => (
            <EuiFlexItem key={index} grow={false}>
              <StyledBadge color={colorMap[reason]}>{labelMap[reason]}</StyledBadge>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      ),
      width: '25%',
    },
  ];

  const effectiveColumns = isEnterpriseAccount ? [...enterpriseColumns, ...columns] : columns;

  return (
    <EuiPanel paddingSize="l">
      <EuiTitle size="xs">
        <p>Action Items</p>
      </EuiTitle>
      <EuiBasicTable
        key={tasks?.length}
        loading={loading}
        items={tasks || []}
        rowProps={(task) => ({
          onClick: () => {
            history.push(
              encodeUrl`/organizations/${task?.property?.orgName}/precheck?taskId=${task.id}&`,
            );
          },
        })}
        columns={effectiveColumns}
      ></EuiBasicTable>
      {!isEnterpriseAccount && (
        <EuiFlexItem data-testid="recommendations-view-all" grow={false}>
          <Button
            onClick={() => {
              history.push(encodeUrl`/organizations/${selectedOrganization.name}/precheck`);
            }}
            size="s"
            label={'View All'}
          />
        </EuiFlexItem>
      )}
    </EuiPanel>
  );
};
