import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ExportCode, IStream } from '@onarchipelago/cx/Stream/types';
import gql from 'graphql-tag';
import {
  IGetSnapshotConfigData,
  IGetSnapshotConfigVariables,
} from 'queries/streams/snapshots/types';
import { EuiSwitch } from 'ui';
import {
  Button,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem,
  Select,
  useToast,
} from 'ui';
import { SelectOption } from 'ui/dist/atoms/inputs/select/Select.types';
import ErrorMessage from '@app/components/ErrorMessage/ErrorMessage';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
// FIX ME
// @ts-ignore
import GET_STREAM_SNAPSHOT_CONFIG from '@app/queries/streams/snapshots/getSnapshotConfig.gql';
import { getErrorMessage } from '@app/utils/getErrorMessage';

interface IProps {
  stream: IStream;
}

interface Item {
  name: string;
  isSnapshot: boolean;
  snapshotId?: string;
  streamId: string;
  organizationId: string;
}

const INVALIDATE_EXPORT = gql`
  mutation InvalidateExport($input: InvalidateExportInput!) {
    invalidateExport(input: $input)
  }
`;

const ExportsTab: React.FC<IProps> = ({ stream }) => {
  const { loading, data, error } = useQuery<IGetSnapshotConfigData, IGetSnapshotConfigVariables>(
    GET_STREAM_SNAPSHOT_CONFIG,
    { variables: { streamSlug: stream.slug } },
  );

  const options: Array<SelectOption> = [
    {
      label: '-',
      value: 'NONE',
    },
  ];
  // Add all exports to the list to invalidate except for LOSSES
  stream.configuration.allowedExports
    .filter((allowedExport) => allowedExport !== ExportCode.LOSSES)
    .forEach((allowedExport) => {
      options.push({
        label: allowedExport,
        value: allowedExport,
      });
    });

  const columns: Array<EuiBasicTableColumn<Item>> = [
    {
      field: 'snapshot',
      name: 'Snapshot',
      render: (_, record) => <>{record.name}</>,
    },
    {
      field: 'action',
      name: 'Export Cache',
      render: (_, record) => <InvalidateExportColumn item={record} options={options} />,
    },
  ];

  if (error) {
    return <ErrorMessage title="Could not get snapshot configuration" message={error.message} />;
  }
  if (loading || !data) {
    return <LoadingSpinnerV2 />;
  }
  const items: Array<Item> = [
    {
      isSnapshot: false,
      name: 'Latest',
      organizationId: stream.organizationId,
      snapshotId: null,
      streamId: stream.id,
    },
  ];

  const listOfSnapshots: Array<Item> = data.snapshotConfig.snapshots
    .filter((snapshot) => snapshot.visible)
    .map((snapshot) => ({
      isSnapshot: true,
      name: snapshot.name,
      organizationId: stream.organizationId,
      snapshotId: snapshot.id,
      streamId: stream.id,
    }));

  items.push(...listOfSnapshots);
  return (
    <>
      <EuiBasicTable items={items} rowHeader="table" columns={columns} />
    </>
  );
};

const InvalidateExportColumn: React.FC<{
  item: Item;
  options: Array<SelectOption>;
}> = ({ item, options }) => {
  const toast = useToast();

  const [selected, setSelected] = useState<SelectOption>(options[0]);
  const [deleteIntermediateData, setIntermediateDataDeletion] = useState(true);

  const [invalidateExport, { loading: saving }] = useMutation<
    InvalidateExportData,
    InvalidateExportInput
  >(INVALIDATE_EXPORT, {
    onCompleted: () => {
      toast({
        title:
          'Successfully submitted cache invalidation request. It may take a few minutes for the invalidaton to complete.',
        type: 'success',
      });
    },
    onError: (err) => {
      // If the error is "invalidateExport export not found" then there's nothing to invalidate.
      if (err.message.includes('input: invalidateExport export not found')) {
        toast({
          title: "This export is not cached yet. So there's nothing to invalidate.",
          type: 'success',
        });
      } else {
        toast({ title: getErrorMessage(err), type: 'danger' });
      }
    },
  });

  return (
    <>
      <EuiFlexGroup
        direction="row"
        gutterSize="s"
        alignItems="center"
        justifyContent="flexStart"
        responsive={false}
      >
        <EuiFlexItem grow={false} style={{ width: '175px' }}>
          <Select
            data-testid="cache-invalidate-exporttype"
            options={options}
            value={selected.value}
            fullWidth
            onChange={(v) => {
              setSelected(options.find((x) => x.value === v));
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: '225px' }}>
          <EuiSwitch
            label={deleteIntermediateData ? 'Delete Intermediate Data' : 'Keep Intermediate Data'}
            onChange={(e) => setIntermediateDataDeletion(e.target.checked)}
            checked={deleteIntermediateData}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Button
            disabled={selected.value === 'NONE' || saving}
            data-testid="cache-invalidate-button"
            color="primary"
            onClick={() => {
              invalidateExport({
                variables: {
                  input: {
                    exportType: selected.value,
                    invalidateIntermediateData: deleteIntermediateData,
                    organizationID: item.organizationId,
                    snapshotID: item.isSnapshot ? item.snapshotId : null,
                    streamID: item.streamId,
                  },
                },
              });
            }}
            label="Invalidate Cache"
            loading={saving}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

interface InvalidateExportData {
  invalidateExport: boolean;
}

interface InvalidateExportInput {
  input: {
    exportType: string;
    organizationID?: string;
    streamID?: string;
    snapshotID?: string;
    invalidateIntermediateData: boolean;
  };
}

export default ExportsTab;
