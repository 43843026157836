import React from 'react';
import { generatePath } from 'react-router';
import { EuiIcon, Icon } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { FEATURE_SPOTLIGHT } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { useNavContext } from '../SideNavContext';
import { BulbIcon } from './Hub';
import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';

export const LibraryLink = () => {
  const { selectedOrganization } = useUserSession();

  const libraryLink = generatePath('/organizations/:organizationName/library', {
    organizationName: selectedOrganization?.name,
  });

  return (
    <NavItem
      data-testid="header-library-tab"
      icon={<Icon name="fileText" />}
      link={libraryLink}
      label="Library"
    />
  );
};

export const SovManager = () => {
  const { permissions } = useNavContext();
  const { selectedOrganization } = useUserSession();

  if (!permissions.canViewSOVManager || !selectedOrganization) {
    return null;
  }

  const isSpotlightEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_SPOTLIGHT,
  );

  const spotlightLink = generatePath('/organizations/:organizationName/spotlight', {
    organizationName: selectedOrganization.name,
  });

  const viewLink = generatePath('/organizations/:organizationName/streams', {
    organizationName: selectedOrganization.name,
  });

  return (
    <NavGroup title="SOV MANAGER">
      {permissions.canViewInsights && isSpotlightEnabled && (
        <NavItem icon={<EuiIcon type={BulbIcon} />} link={spotlightLink} label="Spotlight" />
      )}
      <NavItem
        testId="header-streams-tab"
        icon={<Icon name="layers" />}
        link={viewLink}
        label="Views"
      />
      {permissions.canViewLibrary && <LibraryLink />}
    </NavGroup>
  );
};
