import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { TaskStatus, TaskType, ValuationOutlierReason } from '@app/graphql/precheck/precheck.types';
import { useDataCompletenessByOrgQuery } from '@app/graphql/precheck/queries/dataCompleteness/__generated__/dataCompleteness.generated';
import { useGetAccountUsersQuery } from '@app/graphql/queries/rbac/__generated__/AccountUsers.generated';
import { Role } from '@app/graphql/types';
import { useQueryState } from '@app/hooks/useQueryState';
import { useTracker } from '@app/hooks/useTracker';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';
import { colorMap } from '../constants/colorMap';
import { labelMap } from '../constants/labelMap';
import { useGetTasks } from './hooks/useGetTasks';
import { filterAndCalculateTotals } from './utils/filterAndCalculateTotals';
import { formatTasksAndOptions } from './utils/formatTaskAndOptions';
import { parseQueryFilters } from './utils/parseQueryFilters';
import { CATReasons, ITaskContext, ValuationReason } from './types';

export const TaskContext = createContext({} as ITaskContext);

interface TaskProviderProps {
  withUsers?: boolean;
  taskType?: TaskType;
  assignedToSelf?: boolean;
  children?: ReactNode;
  isEnterpriseOrgChild?: boolean;
}

export const catReasons = Object.values(CATReasons);
export const outlierReasons = [...Object.values(ValuationOutlierReason), ValuationReason.any];

export const TaskProvider = ({
  withUsers = false,
  taskType = TaskType.PreCheck,
  children,
  assignedToSelf,
  isEnterpriseOrgChild,
}: TaskProviderProps) => {
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const { stream } = useStreamContext();
  const tracker = useTracker();

  // eslint-disable-next-line
  const [_qs, setQueryState] = useQueryState();
  const location = useLocation();
  const [selectedTasks, setSelectedTasks] = useState([]);
  const qs: any = queryString.parse(location.search);
  const client = usePrecheckClient();
  const [sortField, setSortField] = useState('priority');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const selectedTaskId = qs?.taskId;
  const setSelectedTaskId = (taskId) => {
    setQueryState({ taskId });
  };

  const filters = parseQueryFilters(qs);

  const { data, loading, error, refetch } = useGetTasks(
    filters,
    taskType,
    assignedToSelf,
    isEnterpriseOrgChild,
    sortField,
    sortDirection,
  );

  const { data: availableUsers } = useGetAccountUsersQuery({
    skip: !withUsers,
    variables: {
      input: {
        limit: 1500,
        offset: 0,
        orgName: selectedOrganization.name,
        // FIX ME
        // @ts-ignore
        roleFilter: [Role.RiskManager, Role.Editor, Role.PropertyEditor],
      },
    },
  });

  const { data: statData, loading: statLoading } = useDataCompletenessByOrgQuery({
    client,
    nextFetchPolicy: 'cache-first',
    skip: taskType === TaskType.PreCheck,
    variables: {
      input: {
        OrgName: selectedOrganization.name,
      },
    },
  });

  const userOptions = (availableUsers?.accountUsers || []).reduce(
    (acc, user) => {
      if (user.profile.email !== account.email) {
        acc.push({
          label: `${
            user?.profile?.givenName && user?.profile?.familyName
              ? `${user.profile.givenName} ${user.profile.familyName.replace(
                  'temporarily_unavailable',
                  '',
                )}`
              : user.profile.email
          }`,
          value: user.profile.email,
        });
      }

      return acc;
    },
    [{ label: `${account.givenName} ${account.familyName}`, value: account.email }],
  );

  const { reasonOptions, transformedTasks } = useMemo(
    () => formatTasksAndOptions(data?.getTasks?.tasks, taskType),
    [data],
  );

  let filtersApplied;

  if (taskType === TaskType.PreCheck) {
    filtersApplied =
      filters.name?.length > 0 ||
      filters.users.length !== 0 ||
      filters.dismissed ||
      filters.reasons.length > 0;
  } else {
    filtersApplied =
      filters.name?.length > 0 ||
      filters.users.length !== 0 ||
      filters.dismissed ||
      filters.reasons.length > 0 ||
      filters.maison?.length > 0 ||
      filters.region?.length > 0;
  }

  const reasonOptionsString = Object.keys(reasonOptions);

  const { filteredTasks, taskTotals } = useMemo(
    () => filterAndCalculateTotals(filters, transformedTasks, reasonOptions, taskType),

    [filters, transformedTasks, reasonOptions],
  );

  const reasonOptionsFormatted = useMemo(() => {
    const data = Object.keys(reasonOptions).map((reason: any) => ({
      color: colorMap[reason],
      label: labelMap[reason],
      value: reason,
    }));

    if (catReasons.filter((item) => reasonOptionsString.includes(item)).length > 1) {
      data.push({
        color: 'rgb(13, 106, 100)',
        label: 'All CAT modifiers',
        value: CATReasons.anyCat,
      });
    }

    if (outlierReasons.filter((item) => reasonOptionsString.includes(item)).length > 1) {
      data.push({
        color: 'rgb(13, 106, 100)',
        label: 'All valuation outliers',
        value: ValuationReason.any,
      });
    }

    return data;
  }, [reasonOptions]);

  const setTableState = (pageSize: number, page: number) => {
    setQueryState({ page: page.toString(), pageSize: pageSize.toString() });
  };

  taskTotals.totalSelectedCount = selectedTasks.length;

  if (selectedTasks.length === filteredTasks.length && taskType === TaskType.ValueCollection) {
    taskTotals.totalSelectedCount = data?.getTasks?.totalCount;
  }

  const readyTasksCount =
    taskType === TaskType.ValueCollection
      ? statData?.dataCompletenessByOrg?.taskStats.find((stat) => stat.status === TaskStatus.Ready)
          ?.totalCount
      : taskTotals.readyTasksCount;

  const dismissedTasksCount =
    taskType === TaskType.ValueCollection
      ? statData?.dataCompletenessByOrg?.taskStats.find(
          (stat) => stat.status === TaskStatus.Dismissed,
        )?.totalCount
      : transformedTasks.filter((task) => task.status === TaskStatus.Dismissed).length;

  return (
    <TaskContext.Provider
      value={{
        dismissedTasksCount,
        error,
        filteredTiv: formatStreamCurrency(taskTotals.filteredTiv),
        filters: {
          clearFilters: () => {
            setQueryState({
              category: null,
              dismissed: null,
              hazards: null,
              maison: null,
              name: null,
              reasons: null,
              region: null,
              users: null,
            });
          },
          dismissed: filters.dismissed,
          filtersApplied,
          maison: filters.maison,
          name: filters.name,
          reasonOptions: reasonOptionsFormatted,
          reasons: filters.reasons,
          region: filters.region,
          selfAssigned: filters.users.includes(account.email),
          setFilter: (filterName, filterValue) => {
            tracker.track('Pre-Check: Filter Applied', {
              filterName,
              filterValue,
            });
            const newQueryState = { [filterName]: filterValue as string, page: '0' };
            setQueryState(newQueryState);
            setSelectedTasks([]);
          },
          userOptions,
          users: filters.users,
        },
        loading: loading || statLoading,
        readyTasksCount: readyTasksCount,
        refetch,
        selectedTaskId,
        selectedTasks,
        setSelectedTaskId,
        setSelectedTasks,
        setSortDirection,
        setSortField,
        sortDirection,
        sortField,
        streamSlug: stream?.slug,
        tableState: {
          page: filters.page,
          pageSize: filters.pageSize,
          setTableState,
          total: data?.getTasks?.totalCount || 0,
        },
        taskTotals,
        taskType,
        tasks: filteredTasks,
        tivPercent: `${Math.round((taskTotals.filteredTiv / stream?.totalInsuredValue) * 100)}%`,
        totalTiv: stream?.totalInsuredValue || 0,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => useContext(TaskContext);
