import React from 'react';
import styled from '@emotion/styled';
import { EuiFlyout, EuiFlyoutBody } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { RocketChat } from '@app/components/RocketChat/RocketChat';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useGetSupportTicketQuery } from '@app/graphql/queries/support/__generated__/getSupportTicket.generated';
import { SupportTicket } from '@app/graphql/types';
import TicketFlyoutHeader from './TicketFlyoutHeader';
import { TicketPreview } from './TicketPreview';

const StyledFlyout = styled(EuiFlyout)`
  inline-size: 50vw !important;
`;

const StyledFlyoutBody = styled(EuiFlyoutBody)`
  .euiFlyoutBody__overflowContent {
    height: 100%;
  }
`;

interface Props {
  ticketId: string;
  onClose: () => void;
  refetch: () => void;
}

const TicketFlyout: React.FC<Props> = ({ ticketId, onClose, refetch }) => {
  const {
    account: { email },
  } = useAuth();

  const { data, loading } = useGetSupportTicketQuery({
    variables: { input: { id: ticketId } },
  });

  const ticket = data?.supportTicket?.ticket as SupportTicket;

  const isCurrentUserAssigned = ticket?.assigneeEmails?.includes(email);
  const isCurrentUserFollowing = ticket?.followerEmails?.includes(email);

  if (loading) {
    return (
      <StyledFlyout paddingSize="none" onClose={onClose}>
        <LoadingSpinnerV2 />
      </StyledFlyout>
    );
  }

  if (!isCurrentUserAssigned && !isCurrentUserFollowing && ticket?.authorEmail !== email) {
    return (
      <StyledFlyout paddingSize="none" onClose={onClose}>
        <TicketFlyoutHeader selfAssign ticket={ticket} refetch={refetch} />
        <StyledFlyoutBody>
          <TicketPreview ticket={ticket} />
        </StyledFlyoutBody>
      </StyledFlyout>
    );
  }

  return (
    <StyledFlyout paddingSize="none" onClose={onClose}>
      <TicketFlyoutHeader refetch={refetch} ticket={ticket} />
      {ticket?.chatRoomName && (
        <StyledFlyoutBody>
          <RocketChat initialRoom={ticket?.chatRoomName} room={ticket?.chatRoomName} />
        </StyledFlyoutBody>
      )}
    </StyledFlyout>
  );
};

export default TicketFlyout;
