import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import MyProperties from '@onarchipelago/cx/Stream/MyProperties';
import { AccountSettings } from '@app/components/AccountSettings/AccountSettings';
import { CasualtyPage } from '@app/components/CasualtyPage/CasualtyPage';
import { Home } from '@app/components/Home/Home';
import { STREAMS_PATH } from '@app/components/Layout/constants';
import { Portfolios } from '@app/components/Portfolios/Portfolios';
import { SpotLightContainer } from '@app/components/SpotLightContainer/SpotLightContainer';
import { SupportPage } from '@app/components/SupportPage/SupportPage';
import { SupportTicket } from '@app/components/SupportPage/SupportTicket/SupportTicket';
import { useRedirectURL } from '@app/containers/Auth/RedirectURL';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { userHasSubmissionAccess } from '@app/cx/Dashboard/helpers';
import { PropertiesPage } from '@app/cx/Properties/PropertiesPage';
import { StreamProvider } from '@app/cx/Stream/StreamProvider';
import { TaskProvider } from '@app/precheck/Components/V2/context/TaskContext';
import { PrecheckRecommendationsExport } from '@app/precheck/Pages/PrecheckRecommendationsExport';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import {
  DOMAINS_PATH,
  FLAGS_PATH,
  INTEGRATIONS_PATH,
  MP_ADD_PROPERTIES_PATH,
  MP_EDIT_PROPERTIES_PATH,
  MP_STREAM_PATH,
  ORG_SUBMISSIONS_PATH,
  ORG_SYSTEM_OVERVIEW,
  PROJECTS_DETAILS_PATH,
  PROJECTS_PATH,
  REPORT_PATH,
  STREAM_ACTION_PATH,
  STREAM_ADD_PROPERTIES_PATH,
  STREAM_EDIT_PROPERTIES_PATH,
  STREAM_PATH,
  STREAM_SYSTEM_OVERVIEW,
  SUBMISSIONS_PATH_BASE,
  SYSTEM_ALERTS_CONFIG_PATH,
  VIEWERS_PATH,
} from './constants';
import { getRedirectPathName, shouldRedirectToUpload } from './utils';

const JobsController = loadable(() => import('@app/dice/ProvenanceEnrichment/JobsController'));

const AccountsPage = loadable(() => import('@app/cx/Dashboard/Submissions/AccountsPage'));
const Logout = loadable(() => import('@app/containers/Auth/Logout'));
const Callback = loadable(() => import('@app/components/Callback/Callback'));

const Share = loadable(() => import('@app/containers/Share/Share'));

const Copilot = loadable(() => import('@app/components/Copilot/Copilot'));
const PrecheckReport = loadable(() => import('@app/precheck/Pages/PrecheckReport'));

const Organizations = loadable(() => import('@onarchipelago/cx/Organizations/Organizations'));
const File = loadable(() => import('@app/containers/File/File'));
const MyJobs = loadable(() => import('@onarchipelago/dice/MyJobs/MyJobs'));

const EnrichmentProjects = loadable(
  () => import('@onarchipelago/dice/EnrichmentProjects/EnrichmentProjects'),
);
const Personnel = loadable(() => import('@onarchipelago/cx/Personnel/Personnel'));
const ProjectDetails = loadable(
  () => import('@onarchipelago/dice/EnrichmentProjects/ProjectDetails'),
);

const UploadPortal = loadable(() => import('@onarchipelago/cx/UploadPortal/UploadPortal'));

const Reports = loadable(() => import('@onarchipelago/cx/Reports/Reports'));

const Flags = loadable(() => import('@onarchipelago/platform/SystemSettings/Flags/Flags'));

const Library = loadable(() => import('@onarchipelago/platform/Library/Library'));

const System = loadable(() => import('@onarchipelago/platform/SystemSettings/SystemSettings'));
const StreamUsers = loadable(() => import('@onarchipelago/platform/StreamUsers/StreamUsers'));
const StreamSystemOverview = loadable(
  () => import('@app/platform/SystemOverview/StreamSystemOverview'),
);
const AddPropertiesPage = loadable(
  () => import('@onarchipelago/cx/Stream/AddPropertiesPage/AddPropertiesPage'),
);

const PropertyPage = loadable(() => import('@onarchipelago/cx/PropertyPage/PropertyPage'));
const Integrations = loadable(() => import('@app/platform/Integrations/Integrations'));

const SmartDocsController = loadable(() => import('@app/dice/Pages/SmartDocs/SmartDocsController'));

const AccountUsers = loadable(() => import('@onarchipelago/platform/AccountUsers/AccountUsers'));

const ValueCollection = loadable(() => import('@app/collection/ValueCollection'));

const Routes: FC = () => {
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  // If there's a redirect URL stored in localStorage, then guide the user to that URL.
  if (useRedirectURL.exists()) {
    const uri = useRedirectURL.get() || '';
    useRedirectURL.clear();
    window.location.href = uri;
  }

  const { selectedOrganization } = useUserSession();

  let hasSubmissionAccess = userHasSubmissionAccess(
    account?.submissionCreateOrgs || [],
    selectedOrganization,
  );
  if (!hasSubmissionAccess) {
    const permissions = getOrgPermissions(account, selectedOrganization?.id);
    const canViewAccounts = permissions?.includes('canViewAccounts');
    hasSubmissionAccess = canViewAccounts;
  }

  const willRedirectToUpload = shouldRedirectToUpload(account?.managedOrgs || [], '/', isAdmin);

  // this forces the routes to wait until a selectedOrganization is set
  // for users navigating to the root path of the app. Since the only logic for
  // redirecting users on the root path is either the upload path or the normal
  // app path, we should be able to safely wait here and force an org to be set before
  // sending all users who arent going to upload to the home page
  if (
    !willRedirectToUpload &&
    location.pathname === '/' &&
    !selectedOrganization &&
    account?.permissions.admin
  ) {
    return null;
  }

  const redirectPathname = getRedirectPathName(willRedirectToUpload, selectedOrganization?.name);

  return (
    <Switch>
      <Route path="/logout" render={() => <Logout />} />
      <Route path="/callback" render={() => <Callback />} />
      <ProtectedRoute
        portal
        path="/organizations/:organizationName/precheck/export"
        render={() => (
          <StreamProvider>
            <TaskProvider>
              <PrecheckRecommendationsExport />
            </TaskProvider>
          </StreamProvider>
        )}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/precheck/:streamId"
        render={() => <PrecheckReport />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/precheck/"
        render={() => <PrecheckReport />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/collection/"
        render={() => <ValueCollection />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/spotlight"
        render={() => <SpotLightContainer />}
      />
      <ProtectedRoute path="/organizations/:organizationName/home" render={() => <Home />} />
      <ProtectedRoute
        path="/organizations/:organizationName/casualty"
        render={() => <CasualtyPage />}
      />
      <ProtectedRoute exact path="/support" render={() => <SupportPage />} />
      <ProtectedRoute path="/support/:ticketId" render={() => <SupportTicket />} />
      <ProtectedRoute path="/organizations/:organizationName/copilot" render={() => <Copilot />} />
      <ProtectedRoute
        path="/organizations/:organizationName/alerts-config"
        render={() => <AccountSettings />}
      />
      <ProtectedRoute
        path={SYSTEM_ALERTS_CONFIG_PATH}
        render={() => <System />}
        requiredPermissions={isAdmin}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/jobs/:jobID/:jobType/:status"
        render={() => <JobsController />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/jobs/:jobID/:jobType/properties"
        render={() => <JobsController />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/jobs/:jobID/:jobType"
        render={() => <JobsController />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/jobs/:jobID"
        render={() => <JobsController />}
      />
      <ProtectedRoute path="/jobs/:jobID/:jobType/:status" render={() => <JobsController />} />
      <ProtectedRoute path="/jobs/:jobID/:jobType" render={() => <JobsController />} />
      <ProtectedRoute path="/jobs/:jobID" render={() => <JobsController />} />
      <ProtectedRoute path="/jobs" render={() => <MyJobs />} />
      <ProtectedRoute path={PROJECTS_DETAILS_PATH} render={() => <ProjectDetails />} />
      <ProtectedRoute path={PROJECTS_PATH} render={() => <EnrichmentProjects />} />
      <ProtectedRoute
        path="/organizations/:organizationName/users"
        render={() => <AccountUsers />}
      />
      <ProtectedRoute path={ORG_SYSTEM_OVERVIEW} render={() => <StreamSystemOverview />} />
      {
        <ProtectedRoute
          path="/organizations/:organizationName/streams"
          render={() => (
            <StreamProvider>
              <Portfolios />
            </StreamProvider>
          )}
        />
      }
      {
        <ProtectedRoute
          path="/streams/:streamSlug/property/:propertyId"
          render={() => (
            <StreamProvider>
              <PropertyPage />
            </StreamProvider>
          )}
        />
      }
      <ProtectedRoute path={ORG_SUBMISSIONS_PATH} render={() => <AccountsPage />} />
      <ProtectedRoute path={SUBMISSIONS_PATH_BASE} render={() => <AccountsPage />} />
      {/* Need to define redirects here above the /organizations route definition */}
      <Redirect path="/organizations/:organizationName/submissions" to={ORG_SUBMISSIONS_PATH} />
      <Redirect path="/submissions" to={SUBMISSIONS_PATH_BASE} />
      <ProtectedRoute
        path="/organizations/:organizationName/personnel"
        render={() => <Personnel />}
      />
      <ProtectedRoute
        path={[MP_ADD_PROPERTIES_PATH, STREAM_ADD_PROPERTIES_PATH]}
        render={() => <AddPropertiesPage />}
      />
      <ProtectedRoute
        path={[MP_EDIT_PROPERTIES_PATH, STREAM_EDIT_PROPERTIES_PATH]}
        render={() => <AddPropertiesPage edit />}
      />
      <ProtectedRoute path={MP_STREAM_PATH} render={() => <MyProperties />} />
      <ProtectedRoute path="/organizations/:organizationName/library" render={() => <Library />} />
      <ProtectedRoute
        path="/organizations/:organizationName/documents/:status"
        requiredPermissions={isAdmin}
        render={() => <SmartDocsController />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/documents"
        requiredPermissions={isAdmin}
        render={() => <SmartDocsController />}
      />
      <ProtectedRoute path="/organizations" render={() => <Organizations />} />
      <ProtectedRoute path="/files/:fileId" render={() => <File />} />
      <ProtectedRoute path="/share" render={() => <Share />} />
      <ProtectedRoute portal path="/upload" render={() => <UploadPortal />} />
      <ProtectedRoute
        exact
        strict
        path="/uploads"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: '/upload',
            }}
          />
        )}
      />
      <ProtectedRoute path={REPORT_PATH} render={() => <Reports />} />
      <ProtectedRoute exact strict path="/library" render={() => <Library />} />
      <ProtectedRoute
        exact
        strict
        path="/streams"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: `/organizations/${selectedOrganization?.name}/${STREAMS_PATH}`,
            }}
          />
        )}
      />
      <ProtectedRoute
        exact
        strict
        path="/streams/"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: '/streams',
            }}
          />
        )}
      />
      <ProtectedRoute path={[DOMAINS_PATH, VIEWERS_PATH]} render={() => <StreamUsers />} />
      <ProtectedRoute path={[STREAM_SYSTEM_OVERVIEW]} render={() => <StreamSystemOverview />} />
      <ProtectedRoute path={[INTEGRATIONS_PATH]} render={() => <Integrations />} />
      {
        <ProtectedRoute
          path={[STREAM_PATH, STREAM_ACTION_PATH]}
          render={() => <PropertiesPage />}
        />
      }
      <ProtectedRoute path={FLAGS_PATH} render={() => <Flags />} />
      <ProtectedRoute
        path="/"
        component={({ location }) => {
          if (redirectPathname.includes('undefined') && !selectedOrganization?.name) {
            return <Home />;
          }
          return (
            <Redirect
              to={{
                ...location,
                pathname: redirectPathname,
              }}
            />
          );
        }}
      />
      {/* Code Path never reached because "/" will always trigger */}
      <Route path="*" render={() => <div>Not found</div>} />
    </Switch>
  );
};

export default Routes;
