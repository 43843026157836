import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Badge,
  Button,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  Icon,
  Select,
  useEuiTheme,
} from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUpdateSupportTicketMutation } from '@app/graphql/mutations/support/__generated__/updateSupportTicket.generated';
import { GET_SUPPORT_TICKET } from '@app/graphql/queries/support/getSupportTicket';
import {
  SupportTicket,
  TicketPriority,
  TicketStatus,
  UpdateSupportTicketInput,
} from '@app/graphql/types';
import EditTicket from './EditTicket';

const StyledFlyoutHeader = styled(EuiFlyoutHeader)`
  padding: 24px !important;
`;

interface Props {
  ticket: SupportTicket;
  selfAssign?: boolean;
  refetch: () => void;
}

const statusOptions = [TicketStatus.Open, TicketStatus.Resolved, TicketStatus.Closed].map((s) => ({
  label: s,
  value: s,
}));

const TicketFlyoutHeader: React.FC<Props> = ({ ticket, selfAssign = false, refetch }) => {
  const { account } = useAuth();
  const { chatRoomID, priority, status, assigneeEmails, followerEmails } = ticket;
  const { euiTheme } = useEuiTheme();

  const [updateTicket, { loading }] = useUpdateSupportTicketMutation({
    onCompleted: refetch,
    refetchQueries: [
      { query: GET_SUPPORT_TICKET, variables: { input: { id: ticket.chatRoomID } } },
    ],
  });

  const assignSelf = () => {
    updateTicket({
      variables: {
        input: {
          assigneeEmails: [...ticket.assigneeEmails, account?.email],
          chatRoomID: ticket.chatRoomID,
          followerEmails: [...(ticket?.followerEmails || [])],
          priority: ticket.priority || TicketPriority.None,
          status: ticket.status,
        },
      },
    });
  };

  const baseTicketInput: UpdateSupportTicketInput = {
    assigneeEmails,
    chatRoomID,
    followerEmails,
    priority: priority || TicketPriority.None,
    status: status || TicketStatus.Open,
  };

  return (
    <StyledFlyoutHeader hasBorder>
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiTitle size="s">
          <EuiText>{ticket.title}</EuiText>
        </EuiTitle>
        <Badge color="warning" label={ticket.status} />
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiFlexGroup alignItems="flexStart" gutterSize="s" justifyContent="spaceBetween">
        <EuiTitle size="xs">
          <EuiFlexGroup alignItems="center" gutterSize="s">
            {ticket.org?.name && (
              <>
                <Icon name="users" />
                <EuiTextColor color={euiTheme.colors.primary}>
                  <Link to={encodeUrl`/organizations/${ticket.org?.name}/streams`}>
                    {ticket.org?.name}
                  </Link>
                </EuiTextColor>
              </>
            )}
            By <a href={`mailto:${ticket.authorEmail}`}>{ticket.authorEmail}</a>
          </EuiFlexGroup>
        </EuiTitle>
        {/* {account?.permissions?.admin && !selfAssign && !isEdit && (
          <Button onClick={onEdit} label="Edit" />
        )} */}
        {selfAssign && (
          <Button
            disabled={loading}
            loading={loading}
            onClick={assignSelf}
            label="Assign to myself"
          />
        )}
        {!selfAssign && account?.permissions?.admin && (
          <EuiFlexItem>
            <Select
              options={statusOptions}
              value={status}
              loading={loading}
              onChange={(e) => {
                updateTicket({
                  variables: {
                    input: {
                      ...baseTicketInput,
                      status: e as TicketStatus,
                    },
                  },
                });
              }}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      {account?.permissions?.admin && !selfAssign && (
        <>
          <EuiSpacer size="s" />
          <EditTicket refetch={refetch} ticket={ticket} onCancel={() => {}} />
        </>
      )}
    </StyledFlyoutHeader>
  );
};

export default TicketFlyoutHeader;
