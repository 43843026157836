import gql from 'graphql-tag';

export const GET_SUPPORT_TICKETS_PAGE = gql`
  query GetSupportTicketsPage($input: SupportTicketsPageInput!) {
    supportTicketsPage(input: $input) {
      tickets {
        title
        status
        priority
        authorEmail
        assigneeEmails

        createdAt
        updatedAt

        chatRoomID
        chatRoomName

        org {
          id
          name
        }
      }
    }
  }
`;
