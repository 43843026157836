import { useUserSession } from '@app/contexts/UserSessionContext';
import {
  BinaryOperator,
  Sort,
  SortAttribute,
  SortOrder,
  TaskStatus,
  TaskType,
} from '@app/graphql/precheck/precheck.types';
import { useGetTasksQuery } from '@app/graphql/precheck/queries/getTasks/__generated__/getTasks.generated';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';

interface Input {
  filter?: Array<{ name: string; operator: BinaryOperator; values: string[] }>;
  pageOffset?: number;
  pageSize?: number;
  reasons?: string[];
  orgName?: string;
  assignees?: string[];
  taskType?: TaskType;
  status?: TaskStatus[];
  sortBy?: Sort[];
}

export const addFiltersToInput = (filters, taskType) => {
  const input: Input = { filter: [] };
  input.pageOffset = filters.pageOffset;
  input.pageSize = filters.pageSize;
  input.taskType = taskType;
  if (filters.dismissed) {
    input.status = [TaskStatus.Dismissed];
  } else {
    input.status = [TaskStatus.Ready];
  }

  if (filters.reasons?.length > 0) {
    input.reasons = filters.reasons;
  }

  if (filters.users?.length > 0) {
    input.assignees = filters.users;
  }

  if (filters.name?.length > 0) {
    input.filter.push({
      name: 'propertySearch',
      operator: BinaryOperator.Contains,
      values: filters.name,
    });
  }

  if (filters.region?.length > 0) {
    input.filter.push({
      name: 'region',
      operator: BinaryOperator.Contains,
      values: filters.region,
    });
  }

  if (filters.maison?.length > 0) {
    input.filter.push({
      name: 'ownerText6',
      operator: BinaryOperator.Contains,
      values: filters.maison,
    });
  }

  return input;
};

export const useGetTasks = (
  filters,
  taskType,
  assignedToSelf,
  isEnterpriseOrgChild,
  sortField,
  sortDirection,
) => {
  const { selectedOrganization } = useUserSession();
  const client = usePrecheckClient();

  let input: Input = { taskType };

  if (assignedToSelf) {
    input.orgName =
      isEnterpriseOrgChild || selectedOrganization.orgLevel === 'Direct'
        ? selectedOrganization.name
        : undefined;
    input.pageOffset = 0;
    input.pageSize = 3;
  } else if (taskType === TaskType.PreCheck) {
    input.orgName = selectedOrganization.name;
  } else {
    const order = sortDirection === 'asc' ? SortOrder.Ascending : SortOrder.Descending;
    const attributeMap = {
      Property: SortAttribute.BuildingName,
      priority: SortAttribute.PriorityRank,
      tiv: SortAttribute.TotalInsuredValueDisplay,
    };
    input = {
      orgName: selectedOrganization.name,
      ...addFiltersToInput(filters, taskType),
      sortBy: [{ attribute: attributeMap[sortField], order }],
    };
  }

  const { data, loading, error, refetch } = useGetTasksQuery({
    client,

    variables: {
      input,
    },
  });

  return { data, error, loading, refetch };
};
