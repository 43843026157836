export const FEATURE_TYPE_AIRTRAFFICCONTROL = 'AirTrafficControl';
export const FEATURE_TYPE_CAE_2 = 'ChangeAnalysis2.0';
export const FEATURE_TYPE_CONFIGURABLE_EXPORTS = 'ConfigurableExports';
export const FEATURE_TYPE_DITTO = 'Ditto - Off';
export const FEATURE_TYPE_DOCUMENTS_PORTAL = 'DocumentsPortal';
export const FEATURE_TYPE_EXTERNALREPORTREADREPLICA = 'ExternalReportReadReplica';
export const FEATURE_TYPE_LIVECHAT = 'LiveChat';
export const FEATURE_TYPE_PROPERTY_REPORTING_VIEWS = 'PropertyReportingViews';
export const FEATURE_TYPE_SOV_MANAGER_8 = 'SOV Manager 8.0';
export const FEATURE_TYPE_SELFSERVICEUSERMGMT = 'SelfServiceUserMgmt';
export const FEATURE_TYPE_SMART_DOC = 'SmartDoc';
export const FEATURE_TYPE_SMART_DOCS_OFF = 'SmartDocs - Off';
export const FEATURE_TYPE_UPLOADV2 = 'UploadV2';
export const FEATURE_TYPE_LOSSES = 'Losses';
export const FEATURE_TYPE_VALIDATIONS_OFF = 'PEValidate-Off';
export const FEATURE_TYPE_CASUALTY_ONLY = 'CasualtyOnly';
export const FEATURE_TYPE_GOODDATA_REACT_SDK = 'GoodData React SDK';
export const FEATURE_IN_APP_ALERT_V1 = 'In-App Alerts';
export const FEATURE_SPOTLIGHT = 'Spotlight';
export const FEATURE_SPOTLIGHT_REACT_SDK = 'Spotlight React SDK';
export const FEATURE_IN_APP_ALERT_V2 = 'In-App Alerts V2';
export const FEATURE_VALUE_COLLECTION = 'Value Collection';

export type FeatureTypes =
  | typeof FEATURE_TYPE_AIRTRAFFICCONTROL
  | typeof FEATURE_TYPE_CAE_2
  | typeof FEATURE_TYPE_CONFIGURABLE_EXPORTS
  | typeof FEATURE_TYPE_DITTO
  | typeof FEATURE_TYPE_DOCUMENTS_PORTAL
  | typeof FEATURE_TYPE_EXTERNALREPORTREADREPLICA
  | typeof FEATURE_TYPE_LIVECHAT
  | typeof FEATURE_TYPE_PROPERTY_REPORTING_VIEWS
  | typeof FEATURE_TYPE_SOV_MANAGER_8
  | typeof FEATURE_TYPE_SELFSERVICEUSERMGMT
  | typeof FEATURE_TYPE_SMART_DOC
  | typeof FEATURE_TYPE_SMART_DOCS_OFF
  | typeof FEATURE_TYPE_UPLOADV2
  | typeof FEATURE_TYPE_LOSSES
  | typeof FEATURE_TYPE_VALIDATIONS_OFF
  | typeof FEATURE_TYPE_CASUALTY_ONLY
  | typeof FEATURE_TYPE_GOODDATA_REACT_SDK
  | typeof FEATURE_IN_APP_ALERT_V1
  | typeof FEATURE_SPOTLIGHT
  | typeof FEATURE_SPOTLIGHT_REACT_SDK
  | typeof FEATURE_IN_APP_ALERT_V2
  | typeof FEATURE_VALUE_COLLECTION;

export const AllFeaturesFlags = [
  FEATURE_TYPE_AIRTRAFFICCONTROL,
  FEATURE_TYPE_CAE_2,
  FEATURE_TYPE_CONFIGURABLE_EXPORTS,
  FEATURE_TYPE_DITTO,
  FEATURE_TYPE_DOCUMENTS_PORTAL,
  FEATURE_TYPE_EXTERNALREPORTREADREPLICA,
  FEATURE_TYPE_LIVECHAT,
  FEATURE_TYPE_PROPERTY_REPORTING_VIEWS,
  FEATURE_TYPE_SOV_MANAGER_8,
  FEATURE_TYPE_SELFSERVICEUSERMGMT,
  FEATURE_TYPE_SMART_DOC,
  FEATURE_TYPE_SMART_DOCS_OFF,
  FEATURE_TYPE_UPLOADV2,
  FEATURE_TYPE_LOSSES,
  FEATURE_TYPE_VALIDATIONS_OFF,
  FEATURE_TYPE_CASUALTY_ONLY,
  FEATURE_TYPE_GOODDATA_REACT_SDK,
  FEATURE_IN_APP_ALERT_V1,
  FEATURE_SPOTLIGHT,
  FEATURE_SPOTLIGHT_REACT_SDK,
  FEATURE_IN_APP_ALERT_V2,
  FEATURE_VALUE_COLLECTION,
];
