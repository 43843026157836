import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'ui';
import debounce from '@app/dice/Pages/SmartDocs/components/utils/debounce';
import { useGetUsersQuery } from '@app/graphql/queries/users/__generated__/getUsers.generated';

interface Props {
  onChange: (emails: string[]) => void;
  initialSelected: string[];
  disabled?: boolean;
  internalUsersOnly?: boolean;
  placeholder?: string;
  allowUnknowns?: boolean;
  fullWidth?: boolean;
}
export const UserSelect: React.FC<Props> = ({
  onChange,
  initialSelected,
  disabled,
  internalUsersOnly,
  placeholder,
  allowUnknowns,
  fullWidth = false,
}) => {
  const [userOptions, setUserOptions] = useState([]);

  const {
    data,
    loading: loadingUsers,
    refetch,
  } = useGetUsersQuery({
    variables: {
      filters: internalUsersOnly
        ? {
            emailSubstring: '@onarchipelago.com',
          }
        : null,
    },
  });

  const searchUsers = (emailSubstring: string) => {
    refetch({
      filters: {
        emailSubstring: emailSubstring || (internalUsersOnly ? '@onarchipelago.com' : null),
      },
    });
  };

  useEffect(() => {
    const assigneeEmailsMap = {};

    const initialUserOptions =
      initialSelected?.map((e) => {
        assigneeEmailsMap[e] = true;
        return { label: e, value: e };
      }) || [];

    const users = data?.users?.users || [];
    setUserOptions([
      ...initialUserOptions,
      ...(users || [])
        .filter((u) => !assigneeEmailsMap[u.profile.email])
        .map((u) => ({
          label: u.profile.email,
          value: u.profile.email,
        })),
    ]);
  }, [JSON.stringify(initialSelected), JSON.stringify(data?.users?.users)]);

  return (
    <MultiSelect
      fullWidth={fullWidth}
      disabled={disabled}
      onChange={(e) => {
        onChange(e.map((v) => v.value));
      }}
      placeholder={placeholder}
      initialSelected={initialSelected?.map((e) => ({ label: e, value: e }))}
      onSearchChange={debounce(searchUsers)}
      onCreateOption={
        allowUnknowns
          ? (o) => {
              setUserOptions([...userOptions, { label: o, value: o }]);
              onChange([...initialSelected, o]);
            }
          : null
      }
      async
      options={userOptions}
      isLoading={loadingUsers}
    />
  );
};
