import { ApolloClient } from '@apollo/client';
import { getCodingData, IExportedCoding } from '@onarchipelago/cx/DocumentsModal//utils';
import { ExportCode } from '@onarchipelago/cx/Stream/types';
import gql from 'graphql-tag';
import moment from 'moment';
import QueryString from 'querystring';
import { API_BASE_URL } from '@app/config';
import { AttributeFilter } from '@app/graphql/types';
import { FEATURE_TYPE_CAE_2 } from '@app/platform/SystemSettings/Flags/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import download from '@app/utils/download';
import { isFeatureEnabled } from './FeatureFlags/FeatureFlags';

const getExportURL = (
  streamId: IGraphQLStream['id'],
  exportCode: ExportCode,
  snapshot: string | null,
) => {
  const url = `${API_BASE_URL}/stream/${streamId}/export`;
  const qs: any = {};
  if (exportCode !== ExportCode.ARCHIPELAGO) {
    qs.coding = exportCode;
  }
  if (snapshot) {
    qs.snapshot = snapshot;
  }
  return `${url}?${QueryString.stringify(qs)}`;
};

const getHeader = (contentType: string | undefined, timezoneId: string, token: string) => [
  {
    name: 'Content-Type',
    value: contentType || 'application/octet-stream',
  },
  {
    name: 'Authorization',
    value: `Bearer ${token}`,
  },
  {
    name: 'X-Timezone',
    value: timezoneId,
  },
];

// Shameless copy: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
const validURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i', // fragment locator
  );
  return !!pattern.test(str);
};

const START_EXPORT_JOB_MUTATION = gql`
  mutation ExportStart($input: ExportStartInput!) {
    exportStart(input: $input) {
      exportJobId
      status
      downloadUrl
    }
  }
`;

const START_EXPORT_JOB_MUTATION_V3 = gql`
  mutation ExportStartV3($input: ExportStartV3Input!) {
    exportStartV3(input: $input) {
      exportID
      status
      downloadUrl
    }
  }
`;

const EXPORT_PROGRESS_QUERY = gql`
  query ExportProgress($input: ExportProgressInput!) {
    exportProgress(input: $input) {
      exportJobId
      status
      downloadUrl
    }
  }
`;

const EXPORT_PROGRESS_V3_QUERY = gql`
  query ExportProgressV3($input: ExportProgressV3Input!) {
    exportProgressV3(input: $input) {
      exportID
      status
      downloadUrl
    }
  }
`;

export interface ExportProgressV3Vars {
  input: {
    exportID: string;
    exportCode: ExportCode;
    streamSlug: string;
    organizationName: string;
    snapshotName?: string;
    snapshotToName?: string;
  };
}

export interface ExportKeyValue {
  key: string;
  value: any;
}
export interface StartExportJobVars {
  input: {
    exportCode: ExportCode;
    streamSlug?: string;
    snapshot?: string;
    jobID?: string;
    metadata?: Array<ExportKeyValue>;
    organizationName?: string;
  };
}

export interface StartExportV3Input {
  input: {
    exportCode: ExportCode;
    streamSlug: string;
    snapshotName?: string;
    metadata: ExportKeyValue[];
    organizationName: string;
    filters?: AttributeFilter[];
    visibleAttributes?: string[];
  };
}

export interface StartExportJobResponse {
  exportStart: {
    exportJobId: string;
    status: string;
    downloadUrl: string;
  };
}

export interface StartExportJobV3Response {
  exportStartV3: {
    exportID: string;
    status: string;
    downloadUrl: string;
  };
}

export interface ExportProgressVars {
  input: {
    exportJobId: string;
  };
}

export interface ExportProgressResponse {
  exportProgress: {
    exportJobId: string;
    status: string;
    downloadUrl: string;
  };
}

export interface ExportProgressV3Response {
  exportProgressV3: {
    exportId: string;
    status:
      | 'STARTED'
      | 'FAILED'
      | 'NOT_FOUND'
      | 'GENERATING_INTERMEDIATE_DATA'
      | 'INTERMEDIATE_DATA_GENERATED'
      | 'COMPLETED';
    downloadUrl: string;
  };
}

export const diagnosticsExportClickHandler = async (
  jobId: string,
  client: ApolloClient<object>,
) => {
  const input: StartExportJobVars['input'] = {
    exportCode: ExportCode.DIAGNOSTIC,
    jobID: jobId,
  };
  // TODO - DRY with archipelagoExportClickHandler
  return new Promise<string>((resolve, reject) => {
    const startExportJobMutation = client.mutate<StartExportJobResponse, StartExportJobVars>({
      mutation: START_EXPORT_JOB_MUTATION,
      variables: {
        input,
      },
    });
    // kick off the mutation.
    startExportJobMutation.then((startResponse) => {
      if (startResponse?.errors) {
        reject(startResponse.errors);
        return;
      }

      if (startResponse?.data?.exportStart?.exportJobId) {
        const id = startResponse.data.exportStart.exportJobId;
        const pollInterval = 5 * 1000; // 5 sec
        const endTime = new Date().getTime() + 5 * 60 * 1000; // 5 mins

        const poll = (): void => {
          const now = new Date().getTime();
          if (now >= endTime) {
            reject(new Error('AsyncPoller: reached timeout'));
            return;
          }
          const pollExportProgressQuery = client.query<ExportProgressResponse, ExportProgressVars>({
            query: EXPORT_PROGRESS_QUERY,
            variables: {
              input: {
                exportJobId: id,
              },
            },
          });
          pollExportProgressQuery.then((data2) => {
            const status = data2?.data?.exportProgress?.status;
            if (data2?.errors) {
              reject(data2.errors);
              return;
            }
            if (status === 'STARTED') {
              setTimeout(poll, pollInterval, resolve, reject);
            } else if (status === 'FAILED') {
              reject(new Error('error with export'));
            } else if (status === 'COMPLETED') {
              resolve(data2?.data?.exportProgress?.downloadUrl);
            } else {
              reject(new Error('error with export - should not reach'));
            }
          });
          pollExportProgressQuery.catch(reject);
        };
        poll();
      } else {
        reject(new Error('error with export - should have export id'));
      }
    });
    startExportJobMutation.catch(reject);
  });
};

interface Props {
  client: ApolloClient<object>;
  currentSnapshot?: string | null;
  exportedCoding: IExportedCoding;
  metaData?: Array<ExportKeyValue>;
  orgName?: string;
  streamSlug?: string;
  enabledFeatures?: string[];
  options?: {
    withTimeOut: boolean;
  };
  filters?: AttributeFilter[];
  visibleAttributes?: string[];
}

// Start the Archipelago Export job and polls the progress until completed.
export const archipelagoExportClickHandler = async ({
  client,
  currentSnapshot,
  exportedCoding,
  metaData = [],
  orgName,
  enabledFeatures,
  streamSlug,
  options,
  filters,
  visibleAttributes,
}: Props) => {
  const { exportCode } = exportedCoding; // only for mixpanel tracking?
  if (isFeatureEnabled(enabledFeatures, FEATURE_TYPE_CAE_2)) {
    const { exportCode } = exportedCoding;

    const inputVariables: StartExportV3Input = {
      input: {
        exportCode,
        filters,
        metadata: [...metaData],
        organizationName: orgName,
        snapshotName: currentSnapshot || '',
        streamSlug,
        visibleAttributes,
      },
    };
    return startExportV3AndPoll(inputVariables, client);
  }
  const inputVariables: StartExportJobVars['input'] = {
    exportCode,
    metadata: [...metaData],
    organizationName: orgName,
    snapshot: currentSnapshot || '',
    streamSlug,
  };

  return new Promise<string>((resolve, reject) => {
    const startExportJobMutation = client.mutate<StartExportJobResponse, StartExportJobVars>({
      mutation: START_EXPORT_JOB_MUTATION,
      variables: {
        input: { ...inputVariables },
      },
    });
    // kick off the mutation.
    startExportJobMutation.then((startResponse) => {
      // Track in MixPanel

      if (startResponse?.errors) {
        reject(startResponse.errors);
        return;
      }

      if (startResponse?.data?.exportStart?.exportJobId) {
        const id = startResponse.data.exportStart.exportJobId;

        const pollInterval = 5 * 1000; // 5 sec
        const maxPollTimeInMinutes = 15; // 15 minutes
        const endPollTime = moment().add(maxPollTimeInMinutes, 'minutes'); // when to stop polling

        const poll = (): void => {
          // reject the promise once we're exceeded the max poll time
          if (options?.withTimeOut) {
            if (moment().isAfter(endPollTime)) {
              reject(new Error('Export timed out after 15 minutes.'));
              return; // important because otherwise it keeps polling!
            }
          }

          const pollExportProgressQuery = client.query<ExportProgressResponse, ExportProgressVars>({
            query: EXPORT_PROGRESS_QUERY,
            variables: {
              input: {
                exportJobId: id,
              },
            },
          });
          pollExportProgressQuery.then((data2) => {
            const status = data2?.data?.exportProgress?.status;
            if (data2?.errors) {
              reject(data2.errors);
              return;
            }

            if (status === 'STARTED') {
              setTimeout(poll, pollInterval, resolve, reject);
            } else if (status === 'FAILED') {
              reject(new Error('error with export'));
            } else if (status === 'COMPLETED') {
              resolve(data2?.data?.exportProgress?.downloadUrl);
            } else {
              reject(new Error('error with export - should not reach'));
            }
          });
          pollExportProgressQuery.catch(reject);
        };
        poll();
      } else {
        reject(new Error('error with export - should have export id'));
      }
    });
    startExportJobMutation.catch(reject);
  });
};

export const exportClickHandler = async (
  streamId: string,
  currentSnapshot: string | null,
  exportedCoding: IExportedCoding,
  token: string,
) => {
  const { contentType, exportCode } = exportedCoding;
  const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = getExportURL(streamId, exportCode, currentSnapshot);
  return new Promise<void>((resolve, reject) => {
    download({
      headers: getHeader(contentType, timezoneId, token),
      url,
    })
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
};

export enum Pal {
  Policy = 'policy',
  Loss = 'loss',
}
export const exportPalClickHandler = async (
  pal: Pal,
  streamId: string,
  currentSnapshot: string | null,
  token: string,
) => {
  const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let url = `${API_BASE_URL}/stream/${streamId}/${pal}/export`;
  const qs: any = {};

  if (currentSnapshot) {
    qs.snapshot = currentSnapshot;
  }
  url = `${url}?${QueryString.stringify(qs)}`;

  return new Promise<void>((resolve, reject) => {
    download({
      headers: getHeader('application/vnd.ms-excel', timezoneId, token),
      url,
    })
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
};

export const rapidExportClickHandler = async (
  streamId: string,
  currentSnapshot: string | null,
  exportedCoding: IExportedCoding,
  token: string,
) => {
  const { exportCode } = exportedCoding;
  const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = getExportURL(streamId, exportCode, currentSnapshot);

  return new Promise<void>((resolve, reject) => {
    fetch(`${url}`, {
      headers: {
        'X-Timezone': timezoneId,
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      method: 'GET',
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          response
            .json()
            .then((val) => {
              if (val?.url && validURL(val.url)) {
                window.location.href = val.url;
                resolve();
              } else {
                reject(new Error(`URL Provided was invalid '${val?.url || ''}'`));
              }
            })
            .catch((e) => {
              reject(e);
            });
        } else {
          console.error(response);
          reject(new Error('Unexpected status'));
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Start the Change Analysis Export job and polls the progress until completed.
const startExportAndPoll = async (
  streamSlug: string,
  currentSnapshot: string, // snapshotTo
  changesSince: string | undefined, // snapshotFrom
  exportedCoding: IExportedCoding,
  client: ApolloClient<object>,
  streamId: string | null,
) => {
  const { exportCode } = exportedCoding; // only for mixpanel tracking?

  // Input for change analysis
  let input = {
    exportCode,
    metadata: [
      {
        key: 'snapshotTo',
        value: currentSnapshot,
      },
    ],
    snapshot: changesSince || '',
    streamSlug,
  };

  // Input when there's no change analysis. Like RMS or AIR exports.
  if (!changesSince) {
    input = {
      exportCode,
      metadata: [],
      snapshot: currentSnapshot || '',
      streamSlug,
    };
  }
  if (exportedCoding.exportCode == ExportCode.DOCUMENTS_ARCHIVE) {
    input = {
      exportCode,
      metadata: [
        {
          key: 'streamID',
          value: streamId,
        },
      ],
      snapshot: currentSnapshot || '',
      streamSlug,
    };
  }
  return new Promise<string>((resolve, reject) => {
    const startExportJobMutation = client.mutate<StartExportJobResponse, StartExportJobVars>({
      mutation: START_EXPORT_JOB_MUTATION,
      variables: {
        input,
      },
    });
    // kick off the mutation.
    startExportJobMutation.then((startResponse) => {
      if (startResponse?.errors) {
        reject(startResponse.errors);
        return;
      }

      if (startResponse?.data?.exportStart?.exportJobId) {
        const id = startResponse.data.exportStart.exportJobId;

        const pollInterval = 5 * 1000; // 5 sec
        const endTime = new Date().getTime() + 10 * 60 * 1000; // 10 mins

        const poll = (): void => {
          const now = new Date().getTime();
          if (now >= endTime) {
            reject(new Error('AsyncPoller: reached timeout'));
            return;
          }

          const pollExportProgressQuery = client.query<ExportProgressResponse, ExportProgressVars>({
            query: EXPORT_PROGRESS_QUERY,
            variables: {
              input: {
                exportJobId: id,
              },
            },
          });
          pollExportProgressQuery.then((data2) => {
            const status = data2?.data?.exportProgress?.status;
            if (data2?.errors) {
              reject(data2.errors);
              return;
            }

            if (status === 'STARTED') {
              setTimeout(poll, pollInterval, resolve, reject);
            } else if (status === 'FAILED') {
              reject(new Error('error with export'));
            } else if (status === 'COMPLETED') {
              resolve(data2?.data?.exportProgress?.downloadUrl);
            } else {
              reject(new Error('error with export - should not reach'));
            }
          });
          pollExportProgressQuery.catch(reject);
        };
        poll();
      } else {
        reject(new Error('error with export - should have export id'));
      }
    });
    startExportJobMutation.catch(reject);
  });
};

const startExportV3AndPoll = async (
  inputVariables: StartExportV3Input,
  client: ApolloClient<object>,
) =>
  new Promise<string>((resolve, reject) => {
    const startExportJobMutation = client.mutate<StartExportJobV3Response, StartExportV3Input>({
      mutation: START_EXPORT_JOB_MUTATION_V3,
      variables: inputVariables,
    });

    // kick off the mutation.
    startExportJobMutation.then((startResponse) => {
      if (startResponse?.errors) {
        reject(startResponse.errors);
        return;
      }

      if (startResponse?.data?.exportStartV3?.exportID) {
        const id = startResponse.data.exportStartV3.exportID;

        const pollInterval = 5 * 1000; // 5 sec

        const vars: ExportProgressV3Vars['input'] = {
          exportCode: inputVariables['input']['exportCode'],
          exportID: id,
          organizationName: inputVariables['input']['organizationName'],
          snapshotName: inputVariables['input']['snapshotName'] || '',
          streamSlug: inputVariables['input']['streamSlug'],
        };

        for (let i = 0; i < inputVariables['input']['metadata'].length; i++) {
          if (inputVariables['input']['metadata'][i].key === 'snapshotTo') {
            vars['snapshotToName'] = inputVariables['input']['metadata'][i].value;
          }
        }

        const poll = (): void => {
          const pollExportProgressQueryV3 = client.query<
            ExportProgressV3Response,
            ExportProgressV3Vars
          >({
            query: EXPORT_PROGRESS_V3_QUERY,
            variables: { input: vars },
          });
          pollExportProgressQueryV3.then((data2) => {
            const status = data2?.data?.exportProgressV3?.status;
            if (data2?.errors) {
              reject(data2.errors);
              return;
            }

            switch (status) {
              case 'FAILED':
                reject(new Error('error with export'));
                break;

              case 'COMPLETED':
                resolve(data2?.data?.exportProgressV3?.downloadUrl);
                break;

              default:
                setTimeout(poll, pollInterval, resolve, reject);
                break;
            }
          });
          pollExportProgressQueryV3.catch(reject);
        };
        // We need a longer interval here because the new exportV3 api is designed to return not found for a longer period of time while exports propogate
        setTimeout(() => poll(), 15000);
      } else {
        reject(new Error('error with export - should have export id'));
      }
    });
    startExportJobMutation.catch(reject);
  });

export const airExportHandler = async (
  streamSlug: string,
  currentSnapshot: string, // snapshotTo
  enabledFeatures: string[] | null,
  orgName: string,
  client: ApolloClient<object>,
) => {
  if (isFeatureEnabled(enabledFeatures, FEATURE_TYPE_CAE_2)) {
    const { exportCode } = getCodingData(ExportCode.AIR);

    const inputVariables: StartExportV3Input = {
      input: {
        exportCode,
        metadata: [],
        organizationName: orgName,
        snapshotName: currentSnapshot || '',
        streamSlug,
      },
    };
    return startExportV3AndPoll(inputVariables, client);
  }

  return startExportAndPoll(
    streamSlug,
    currentSnapshot,
    undefined,
    getCodingData(ExportCode.AIR),
    client,
    null,
  );
};

export const rmsExportHandler = async (
  streamSlug: string,
  currentSnapshot: string, // snapshotTo
  enabledFeatures: string[] | null,
  orgName: string,
  client: ApolloClient<object>,
) => {
  if (isFeatureEnabled(enabledFeatures, FEATURE_TYPE_CAE_2)) {
    const { exportCode } = getCodingData(ExportCode.RMS);

    const inputVariables: StartExportV3Input = {
      input: {
        exportCode,
        metadata: [],
        organizationName: orgName,
        snapshotName: currentSnapshot || '',
        streamSlug,
      },
    };
    return startExportV3AndPoll(inputVariables, client);
  }
  return startExportAndPoll(
    streamSlug,
    currentSnapshot,
    undefined,
    getCodingData(ExportCode.RMS),
    client,
    null,
  );
};
export const documentZipExportHandler = async (
  streamSlug: string,
  streamID: string,
  currentSnapshot: string,
  client: ApolloClient<object>,
) =>
  startExportAndPoll(
    streamSlug,
    currentSnapshot,
    undefined,
    getCodingData(ExportCode.DOCUMENTS_ARCHIVE),
    client,
    streamID,
  );

export const changeAnalysisExportHandler = async (
  streamSlug: string,
  currentSnapshot: string, // snapshotTo
  changesSince: string, // snapshotFrom
  orgName: string,
  enabledFeatures: string[] | null,
  client: ApolloClient<object>,
  filters,
): Promise<string> => {
  if (isFeatureEnabled(enabledFeatures, FEATURE_TYPE_CAE_2)) {
    const { exportCode } = getCodingData(ExportCode.CHANGE_ANALYSIS);

    const inputVariables: StartExportV3Input = {
      input: {
        exportCode,
        filters,
        metadata: [
          {
            key: 'snapshotTo',
            value: currentSnapshot,
          },
        ],
        organizationName: orgName,
        snapshotName: changesSince || '',
        streamSlug,
      },
    };
    return startExportV3AndPoll(inputVariables, client);
  }
  return startExportAndPoll(
    streamSlug,
    currentSnapshot,
    changesSince,
    getCodingData(ExportCode.CHANGE_ANALYSIS),
    client,
    null,
  );
};
