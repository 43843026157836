export enum CarrierExportCode {
  RAPID = 'RAPID',
}

export interface SetOrgSubmissionConfigInput {
  orgName: string;
  enableDashboard: boolean;
  templateOrgName?: string;
  refreshFromTemplate?: boolean;
}

export interface SetOrgPropertyConfigInput {
  orgId: string;
  enableLosses?: boolean;
  lossStartYear?: number;
}
export interface OrgExportConfigInput {
  orgId: string;
  carrierExports: Array<CarrierExportCode>;
}

export interface InitOrgConfigVariables {
  propertyInput: SetOrgPropertyConfigInput;
  exportInput: OrgExportConfigInput;
  submissionInput: SetOrgSubmissionConfigInput;
}
