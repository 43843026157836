import * as Types from '../../../precheck.types';

import { gql } from '@apollo/client';
export type RecommendationFragment = { __typename?: 'Recommendation', archipelagoID: string, bucket?: number | null, valuationOutlierReason?: Types.ValuationOutlierReason | null, priorityRank?: number | null, attribute?: Array<{ __typename?: 'Attribute', name: string, recommendationType: Types.RecommendationType, documents?: Array<string | null> | null, reasons?: Array<string | null> | null, attributeMetadata?: { __typename?: 'AttributeMetadata', columnPosition: number, dataType: string, derived: boolean, displayName: string, displayWidth: number, filterable: boolean, groupable: boolean, hiddenInGrid: boolean, name: string, enumMetadata?: Array<{ __typename?: 'EnumValueMetadata', valueID: string, value: string, valueDisplay?: string | null, position: number, clusterValue?: string | null, hexColorCode?: string | null, externalMappings?: Array<{ __typename?: 'ExternalMapping', externalCoding: Types.ExternalCoding, valueID: string, value: string }> | null, softMappings?: Array<{ __typename?: 'SoftMapping', modelSchema: Types.SoftMappingModelSchema, secondaryModifier: string, value?: string | null } | null> | null }> | null } | null }> | null };

export type RecommendationWithoutMetaFragment = { __typename?: 'Recommendation', archipelagoID: string, bucket?: number | null, valuationOutlierReason?: Types.ValuationOutlierReason | null, priorityRank?: number | null, attribute?: Array<{ __typename?: 'Attribute', name: string, recommendationType: Types.RecommendationType, documents?: Array<string | null> | null, reasons?: Array<string | null> | null }> | null };

export const RecommendationFragmentDoc = gql`
    fragment recommendation on Recommendation {
  archipelagoID
  bucket
  valuationOutlierReason
  priorityRank
  attribute {
    name
    recommendationType
    documents
    reasons
    attributeMetadata {
      columnPosition
      dataType
      derived
      displayName
      displayWidth
      filterable
      groupable
      hiddenInGrid
      name
      enumMetadata {
        valueID
        value
        valueDisplay
        position
        clusterValue
        hexColorCode
        externalMappings {
          externalCoding
          valueID
          value
        }
        softMappings {
          modelSchema
          secondaryModifier
          value
        }
      }
    }
  }
}
    `;
export const RecommendationWithoutMetaFragmentDoc = gql`
    fragment recommendationWithoutMeta on Recommendation {
  archipelagoID
  bucket
  valuationOutlierReason
  priorityRank
  attribute {
    name
    recommendationType
    documents
    reasons
  }
}
    `;