import React, { FC, useEffect, useState } from 'react';
import { IDistiller, IPageState } from '@onarchipelago/cx/Stream/types';
import { BasicMultiSelect, MultiSelectOption, Select } from 'ui';

interface Props {
  distiller: IDistiller;
  handleChange: (val: string[]) => void;
  pageState: IPageState;
}

const Distiller: FC<Props> = ({ distiller, handleChange, pageState }) => {
  const [value, setValue] = useState<string[] | undefined>(distiller.value);

  const onClear = () => {
    handleChange([]);
    setValue([]);
  };

  const dataTestID = `${distiller.name || ''}-distiller-button`;

  const { inputs } = distiller;
  // FIX ME
  // @ts-ignore
  const distillerOptions: MultiSelectOption[] =
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    inputs && inputs[0].options?.map((option) => ({ label: option.label, value: option.value }));

  return distiller.name === 'groupBy' ? (
    <GroupByMultiSelect
      distiller={distiller}
      handleChange={handleChange}
      onClear={onClear}
      pageState={pageState}
    />
  ) : (
    <Select
      data-testid={dataTestID}
      options={distillerOptions || []}
      onChange={(val) => {
        handleChange([val]);
        setValue([val]);
      }}
      value={value?.[0]}
      compressed
    />
  );
};

interface GroupByMultiSelectProps extends Props {
  onClear: () => void;
}

const GroupByMultiSelect: FC<GroupByMultiSelectProps> = ({
  distiller,
  handleChange,
  pageState,
  onClear,
}) => {
  const dataTestID = `${distiller.name || ''}-distiller-button`;

  const { inputs } = distiller;

  // FIX ME
  // @ts-ignore
  const distillerOptions: MultiSelectOption[] =
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    inputs && inputs[0].options?.map((option) => ({ label: option.label, value: option.value }));

  const initialSelected =
    distiller.value && Array.isArray(distiller.value)
      ? distiller.value
          .filter((option) => option !== '')
          // When the distiller is "groupBy" then don't try to load "changesSince" as an option
          .filter((option) => distiller.name === 'groupBy' && option !== 'changesSince')
          .map((option) => ({
            label: inputs[0].options.find((x) => x.value === option).label,
            value: option,
          }))
      : undefined;

  const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>(
    // FIX ME
    // @ts-ignore
    initialSelected || [],
  );

  useEffect(() => {
    // This clears out the selected Group By values when some other control clears its pageState value.
    if (pageState?.changesSince !== '' && pageState?.groupByV2?.length < 1) {
      setSelectedOptions([]);
    } else {
      // FIX ME
      // @ts-ignore
      setSelectedOptions(initialSelected || []);
    }
  }, [pageState?.changesSince, pageState?.groupByV2]);

  return (
    <BasicMultiSelect
      data-testid={dataTestID}
      options={distillerOptions || []}
      onChange={(value: MultiSelectOption[]) => {
        setSelectedOptions(value);
        const attributes: string[] = value.map((v) => v.value);

        if (value.length < 1) {
          onClear();
          return;
        }
        handleChange(attributes);
      }}
      // FIX ME
      // @ts-ignore
      onClear={onClear}
      selectedOptions={selectedOptions}
      placeholder={'Pivot By'}
      compressed={false}
    />
  );
};

export default Distiller;
