import React, { Dispatch, SetStateAction } from 'react';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { ManageExportsFlyout } from '../ManageExportsFlyout/ManageExportsFlyout';
import { PanelItem } from './types';

interface IUseManageExportsPanel {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const useManageExportsPanel = ({ setOpen }: IUseManageExportsPanel): PanelItem => {
  const { selectedOrganization } = useUserSession();
  const { showFlyout } = useFlyout();

  const { stream } = useStreamContext();
  const streamId = stream?.id;
  const streamSlug = stream?.slug;

  return {
    'data-testid': 'manage-properties-button',
    iconName: 'fileText',
    label: 'Manage Exports',
    onClick: () => {
      setOpen(false);
      showFlyout(
        <ManageExportsFlyout
          orgId={selectedOrganization?.id}
          streamId={streamId}
          streamSlug={streamSlug}
        />,
      );
    },
  };
};
