import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ButtonEmpty, ContextMenu } from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { REPORT_TOOLTIPS, REPORTS } from '@app/cx/Reports/constants';
// FIX ME
// @ts-ignore
import { ReportCode } from '@app/graphql/types';
import { useStreamContext } from '../StreamProvider';
// FIX ME
// @ts-ignore
import { ReportCode } from '../types';

export const ReportsButton = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { stream, permissions } = useStreamContext();
  const history = useHistory();
  const { search } = useLocation();

  const [open, setOpen] = useState<boolean>(false);

  const allowedReports = stream?.allowedReports;
  const reportButtonPanelItems = REPORTS.map(({ id, name, type }) => {
    if (id === 'DQD') {
      return null;
    }
    if (!allowedReports?.includes(id as ReportCode)) {
      return null;
    }
    const tooltip = REPORT_TOOLTIPS.find((tooltip) => tooltip.id === id);
    const tooltipObj = tooltip
      ? { toolTipContent: tooltip.toolTipContent, toolTipPosition: 'right' }
      : {};
    return {
      'data-testid': `${type}-report-button`,
      label: name,
      onClick: () => {
        history.push({
          pathname: encodeUrl`/reports/${stream?.slug}/${type}`,
          search,
        });
      },
      ...tooltipObj,
    };
  }).filter(Boolean);

  const button = (
    <ButtonEmpty
      data-testid="reports-button"
      iconName="chevronDown"
      iconSide="right"
      label="Reports"
      size={'m'}
      onClick={() => setOpen(!open)}
    />
  );

  return (
    allowedReports?.length && (
      <ContextMenu
        data-testid="reports-menu"
        button={button}
        onClose={() => setOpen(false)}
        open={open}
        // FIX ME
        // @ts-ignore
        panel={{ items: reportButtonPanelItems }}
      />
    )
  );
};
