import { ApolloError, ApolloQueryResult } from '@apollo/client';
import {
  Attribute,
  Task,
  TaskStatus,
  TaskType,
  ValuationOutlierReason,
} from '@app/graphql/precheck/precheck.types';
import { GetTasksQuery } from '@app/graphql/precheck/queries/getTasks/__generated__/getTasks.generated';

export interface IFormattedtask {
  assignees: Array<{
    email: string;
    name: string;
  }>;
  status: TaskStatus;
  attributes: Attribute[];
  id: string;
  priority: number;
  propertyId: string;
  propertyUid: string;
  propertyName: string;
  propertyCityAndState: string;
  tiv: string;
  rawTiv: number;
  recommendedDocuments: Array<string>;
  propertyDocuments: any;
  reasons: Array<CATReasons | COPEReason | ValuationReason | AttritionalReason | string>;
  property: Task['property'];
}
export interface ITaskContext {
  tasks: Array<IFormattedtask>;
  readyTasksCount: number;
  loading: boolean;
  error: ApolloError;
  taskType: TaskType;
  tableState: {
    pageSize: number;
    page: number;
    setTableState: (pageSize: number, page: number) => void;
    total: number;
  };
  filters: {
    reasonOptions: Array<{
      label: string;
      value: CATReasons | COPEReason | ValuationReason | ValuationOutlierReason | AttritionalReason;
    }>;
    userOptions: Array<{ label: string; value: string }>;
    name: string | null;
    users: Array<string>;
    dismissed: boolean;
    selfAssigned: boolean;
    maison: string | null;
    region: string | null;
    filtersApplied: boolean;
    reasons: Array<
      CATReasons | COPEReason | ValuationReason | AttritionalReason | ValuationOutlierReason
    >;
    setFilter: (filterName: string, filterValue: string | Array<string>) => void;
    clearFilters: () => void;
  };
  setSelectedTasks: (tasks: Array<IFormattedtask>) => void;
  selectedTaskId: string;
  setSelectedTaskId: (id: string) => void;
  selectedTasks: Array<IFormattedtask>;
  refetch: () => Promise<ApolloQueryResult<GetTasksQuery>>;
  totalTiv: number;
  filteredTiv: string;
  tivPercent: string;
  taskTotals: {
    attritional: {
      tiv: number;
      properties: number;
    };
    cat: {
      tiv: number;
      properties: number;
    };
    cope: {
      tiv: number;
      properties: number;
    };
    valuation: {
      tiv: number;
      properties: number;
    };
    totalSelectedCount: number;
  };
  sortField: string;
  sortDirection: 'asc' | 'desc';
  setSortField: (field: string) => void;
  setSortDirection: (direction: 'asc' | 'desc') => void;
  dismissedTasksCount: number;
  streamSlug: string;
}

export enum CATReasons {
  earthquake = 'Earthquake risk',
  wind = 'Wind risk',
  flood = 'Flood risk',
  storm = 'SCS risk',
  anyCat = 'Any CAT risk',
}

export enum COPEReason {
  cope = 'Missing COPE data',
}

export enum ValuationReason {
  valuation = 'Valuation Outlier',
  any = 'Any Outlier',
}

export enum AttritionalReason {
  attritional = 'Attritional risk',
}
